import StreamModel from '../models/stream.model';
import EnvironmentService from '../services/common/environment.service';
import logger from '../services/common/logger.service';
import AnalyticsEventsService from '../services/analytics/analytics-events.service';
import ItemService from '../services/common/item.service';
import StreamService from '../services/ad-behavior/stream.service';
import MonetizationTools from '../services/common/monetization-tools.service';

export default class StreamAdComponent {
    /** *
     * @param container - dom element to inject stream
     * @param config - template config
     * @param placementData - initialization data passed to component
     */
    constructor(container, config, placementData) {
        this._stream = new StreamModel(config, placementData);
        this._devtools = new MonetizationTools();
        this.placedOutsideOfEmbed = this._shouldPlacedOutsideOfEmbed();
        this.embedId = this._getPlayerEmbedId();
        this.placement = this._createPlacement(container);

        AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.AD_ENABLED, this._stream);
        this._devtools.sendPlacementDetails({
            id: this._stream.id,
            displayName: `${this._stream.type}`,
            type: this._stream.type,
            positionType: this._stream.positionType,
            details: {
                'Placement Status': 'Enabled',
                'Ad Fetch Status': 'Pending',
                'Overlay Status': 'Not Displayed',
                tag: this._stream.tag
            }
        });
    }

    show() {
        this._initEventListeners(EnvironmentService.context.document);
        this._devtools.sendPlacementDetails({
            details: {
                'Ad Fetch Status': 'Receiving Ad'
            }
        });
        return new Promise(resolve => StreamService.loadStreamScript(
            Object.assign({ embedId: this.embedId }, this._stream),
            this.placedOutsideOfEmbed,
        )
            .then(() => {
                logger.debug('Stream Sdk Loaded', this.placement, this._stream);
                AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.AD_INVOKE, this._stream);
                return resolve({ loaded: true });
            })
            .catch(() => {
                logger.error('Stream Sdk Failed to load');
                // if sdk load failed stream will be loaded by story
                this.placement.removeChild(this.placement.childNodes[0]);
                return resolve({ loaded: false });
            }));
    }

    _shouldPlacedOutsideOfEmbed() {
        const { outsideOfEmbed, placement: { videoIndex } } = this._stream;
        return outsideOfEmbed && EnvironmentService.isEmbed && videoIndex === 0;
    }

    _createPlacement(container) {
        // if placement is configured as outsideOfEmbed and only if it is the first section
        if (this.placedOutsideOfEmbed) {
            const parentWindow = EnvironmentService.context;
            return this._createPlacementOutsideOfEmbed(parentWindow);
        }
        return this._createPlacementInsideContainer(container);
    }

    _createPlacementInsideContainer(container) {
        const pbMcdElm = this._createMcdElement();
        container.appendChild(pbMcdElm);
        return container;
    }

    _createPlacementOutsideOfEmbed(parentWindow) {
        const streamContainer = parentWindow.document.createElement('div');
        streamContainer.id = this._stream.id;
        streamContainer.setAttribute('data-pb-ad-type', this._stream.type);
        streamContainer.style.minWidth = '100%';
        streamContainer.style.width = '1px';
        streamContainer.style.paddingBottom = '12px';

        // Create pb-mcd dom element inside stream container
        const pbMcdElm = this._createMcdElement(parentWindow);
        streamContainer.appendChild(pbMcdElm);

        // Inserts the container node before the iframe node
        const frameEl = EnvironmentService.getIframeElement();
        frameEl.parentElement.insertBefore(streamContainer, frameEl);
        return streamContainer;
    }

    _createMcdElement(parentWindow) {
        const pbMcdElm = parentWindow ? parentWindow.document.createElement('pb-mcd') : document.createElement('pb-mcd');
        pbMcdElm.setAttribute('embed-id', this.embedId);
        pbMcdElm.setAttribute('data-item', this._stream.itemId);
        pbMcdElm.setAttribute('data-video', this._stream.videoId);
        pbMcdElm.style.display = 'block';
        pbMcdElm.style.maxWidth = '640px';
        pbMcdElm.style.margin = '0 auto';
        return pbMcdElm;
    }

    _getPlayerEmbedId() {
        if (this._stream.useChannelId && ItemService.channelId) {
            return ItemService.channelId;
        }
        return this._stream.tag;
    }

    _initEventListeners(contextElem) {
        const { STREAM_LOAD } = StreamService.CustomEventsEnum;
        contextElem.addEventListener('pbStreamEvent', (event) => {
            if (!event.detail) {
                return;
            }
            if (event.detail.name === STREAM_LOAD) {
                logger.debug('video ad - stream player loaded', this.placement, this._stream);
                this._devtools.sendPlacementDetails({
                    details: {
                        'Ad Fetch Status': 'Ad Received',
                        'Overlay Status': 'Displayed'
                    }
                });
            }
        }, false);
    }
}
