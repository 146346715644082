import AbstractAd from './abstract-ad.component';
import logger from '../services/common/logger.service';
import StreamService from '../services/ad-behavior/stream.service';
import VideoModel from '../models/video.model';
import AnalyticsEventsService from '../services/analytics/analytics-events.service';
import { AdTypeEnum } from '../constants/ad-types.constant';
import AdFactory from '../services/ad-creation/ad-factory.service'; // eslint-disable-line

export default class VideoAdComponent extends AbstractAd {
    constructor(container, config, placementData) {
        super(container, placementData, AdTypeEnum.VIDEO);
        logger.debug('building video ad', container, config);

        this._video = new VideoModel(config, placementData);
        this._container = container;
        this._initialConfig = Object.assign({}, config);
        this._hasFallback = !!(config.fallbackTag && config.fallbackTag.value);
        this._placementData = placementData;

        this._playerInstance = null;

        this.pbMcdElement = StreamService.createStreamElement(this._video.tag);
        this.adWrapperInner.appendChild(this.pbMcdElement);

        this.createAdDefered();

        this._hideDelay = placementData.hideDelay;
        this._eventsInited = false;
        this._rendered = false;
        this._adShowCalled = false;
        this._hasError = false;

        AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.AD_ENABLED, this._video);
        this._devtools.sendPlacementDetails({
            id: this._video.id,
            displayName: this._getDevToolsDisplayName(placementData),
            type: this._video.type,
            positionType: this._video.positionType,
            details: {
                'Placement Status': 'Enabled',
                'Ad Fetch Status': 'Pending',
                'Overlay Status': 'Not Displayed',
                tag: this._video.tag
            }
        });

        if (this._video.preload) {
            this._initAdScript();
        }

        const fallbackAdConfig = Object.assign({}, this._initialConfig);
        fallbackAdConfig.tag = fallbackAdConfig.fallbackTag;
        fallbackAdConfig.tag.type = 'display';
        this.fallbackAd = AdFactory.createFallbackAd(this._placementData, fallbackAdConfig);
    }

    createAdDefered() {
        this.adDeferred = {
            promise: new Promise((resolve) => {
                this.adShowResolve = (params) => {
                    resolve(params);
                    this.createAdDefered();
                };
            })
        };
    }

    render() {
        return new Promise((resolve) => {
            if (this._rendered === false) {
                this._initEventListeners();
                this._loadStreamScript(true).then((loaded) => {
                    if (loaded) {
                        this._rendered = true;
                    }
                    resolve();
                });
            } else {
                resolve();
            }
        });
    }

    show() {
        this._adShowCalled = true;

        return new Promise((resolve) => {
            this.adDeferred.promise.then(resolve);

            if (this._video.preload) {
                this._handlePreloadedAd();
            } else {
                this._initAdScript();
            }
        });
    }

    pause() {
        if (this._playerInstance && !this._hasError) {
            this._playerInstance.pause();
        }
    }

    resume() {
        if (this._playerInstance && !this._hasError) {
            this._playerInstance.resume();
        }
    }

    refresh() {
        this._adShowCalled = false;
        this.createAdDefered();

        return new Promise((resolve) => {
            this.adDeferred.promise.then(resolve);

            if (!this._playerInstance || this._hasError) {
                if (this._hasFallback) {
                    this._refreshFallbackAd();
                } else {
                    resolve();
                }
            } else if (this._sdk) {
                this._sdk.refresh();
                resolve();
            }
        });
    }

    getDuration() {
        if (!this._playerInstance || (this._hasError && this._hasFallback)) {
            return this.fallbackAd.getDuration();
        }

        const video = this.pbMcdElement.querySelector('#videoslot video');
        return video && video.duration > 0 ? Math.floor(video.duration) * 1000 : null;
    }

    _handlePreloadedAd() {
        if (!this._playerInstance || this._hasError) {
            this._showFallbackAd();
            return;
        }
        AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.AD_INVOKE, this._video);
        this._startAd();
    }

    _loadStreamScript(outsideOfEmbed = false) {
        return new Promise((resolve) => {
            StreamService.loadStreamScript({ embedId: this._video.tag }, outsideOfEmbed)
                .then(() => {
                    resolve(true);
                    logger.debug('Stream Sdk Loaded', this._container, this._config);
                })
                .catch(() => {
                    resolve(false);
                    this._handleError('Stream Sdk Failed to load');
                });
        });
    }

    _initAdScript() {
        AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.AD_REQUEST, this._video);
        this._devtools.sendPlacementDetails({
            details: {
                'Ad Fetch Status': 'Receving Ad'
            }
        });
        this._initEventListeners();

        this._setLoadTimeout();

        if (this._rendered === false) {
            this._loadStreamScript();
        } else {
            this._startAd();
        }
    }

    _setLoadTimeout() {
        this._timer = setTimeout(() => {
            this._handleError('video load timeout has expired');
        }, this._video.timeout);
    }

    _clearLoadTimeout() {
        clearTimeout(this._timer);
    }

    _startAd() {
        this._playerInstance.startTemplate();

        setTimeout(() => {
            if (!this._playerInstance.AdLoaded && !this._playerInstance.playing) {
                this._showFallbackAd();
            }
        }, 2e3);

        AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.AD_STARTED, this._video);
        this._devtools.sendPlacementDetails({
            details: {
                'Ad Fetch Status': 'Ad Received',
                'Overlay Status': 'Displayed'
            }
        });
    }

    // handle load event from stream
    _handleStreamLoad(event) {
        if (event.data && event.data.playerInstance) {
            this._playerInstance = event.data.playerInstance;
            this._sdk = event.data.sdk;
            this._clearLoadTimeout();
            if (this._adShowCalled) {
                this._startAd();
            }
        } else {
            logger.error('video ad - playerInstance does not exist in stream load event', this._container, this._initialConfig);
        }
    }

    // handle all kinds of error in this class
    _handleError(errorMsg) {
        if (errorMsg) {
            logger.debug(errorMsg, this._container, this._config);
        }

        if (this._hasError) {
            return;
        }

        this._hasError = true;
        this._clearLoadTimeout();

        if (this._hasFallback) {
            if (this._adShowCalled) {
                this._showFallbackAd();
            }
            this._closeAd(false);
        } else {
            this._devtools.sendPlacementDetails({
                details: {
                    'Placement Status': 'Disabled',
                    'Ad Fetch Status': 'Ad Not Received'
                }
            });
            this._closeAd();
        }
    }

    _showFallbackAd() {
        if (!this._hasFallback) {
            this.adShowResolve();
            return;
        }
        logger.debug('video ad - show fallback ad', this._container, this._initialConfig);
        this._devtools.sendPlacementDetails({
            details: {
                'Ad Fetch Status': 'Ad Not Received',
                'Overlay Status': 'Display Fallback'
            }
        });
        this.fallbackAd.show().then(this.adShowResolve);
    }

    _refreshFallbackAd() {
        if (!this._hasFallback) {
            this.adShowResolve();
            return;
        }
        logger.debug('video ad - refresh fallback ad', this._container, this._initialConfig);
        this._devtools.sendPlacementDetails({
            details: {
                'Ad Fetch Status': 'Ad Not Received',
                'Overlay Status': 'Refresh Fallback'
            }
        });

        if (this.fallbackAd.refresh) {
            this.fallbackAd.refresh().then(res => this.adShowResolve(res));
        } else {
            this.adShowResolve();
        }
    }

    _initEventListeners() {
        if (this._eventsInited === false) {
            this._eventsInited = true;
            this.pbMcdElement.addEventListener(StreamService.CustomEventName, (event) => {
                if (!event.detail) {
                    return;
                }

                switch (event.detail.name) {
                    case StreamService.CustomEventsEnum.STREAM_LOAD:
                        this._handleStreamLoad(event.detail);
                        logger.debug('video ad - stream player loaded', this._container, this._initialConfig);
                        break;
                    case StreamService.CustomEventsEnum.AD_IMPRESSION:
                        this._handleImpression();
                        logger.debug('video ad has an impression', this._container, this._initialConfig);
                        break;
                    case StreamService.CustomEventsEnum.AD_SKIPPED:
                        this._handleAdSkipped();
                        logger.debug('video ad has been skipped', this._container, this._initialConfig);
                        break;
                    case StreamService.CustomEventsEnum.AD_COMPLETED:
                        this._handleAdCompleted();
                        logger.debug('video ad has been completed', this._container, this._initialConfig);
                        break;
                    case StreamService.CustomEventsEnum.AD_ERROR:
                        this._handleError('video ad has no fill');
                        break;
                    default:
                        break;
                }
            }, false);
        }
    }

    _resizePlayer() {
        const width = this.pbMcdElement.clientWidth;
        const height = width * 9 / 16;
        this._playerInstance.resize(width, height); // resize player by 16/9 ratio
    }

    // handle ad impression event from stream
    _handleImpression() {
        setTimeout(this._resizePlayer.bind(this));
        this._clearLoadTimeout();
        AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.AD_IMPRESSION, this._video);
        this.showAd();
    }

    // handle ad skipped event from stream
    _handleAdSkipped() {
        this._closeAd();
    }

    // handle ad complete event from stream
    _handleAdCompleted() {
        AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.AD_COMPLETE, this._video);
        this._closeAd();
    }

    _closeAd(adResolve = true) {
        if (adResolve) {
            this.adShowResolve();
            this._devtools.sendPlacementDetails({
                details: {
                    'Overlay Status': 'Not Displayed'
                }
            });
        }

        if (this._hideDelay) {
            setTimeout(() => this.hideAd(), this._hideDelay);
        } else {
            this.hideAd();
        }

        if (this._playerInstance) {
            this._playerInstance.close();
        }
    }
}
