import { getQueryStringValue } from '@playbuzz/client-utils';
import EnvironmentService from '../common/environment.service';
import ItemService from '../common/item.service';
import abTestService from '../configuration/ab-test.service';
import DeviceDetector from '../common/device-detection.service';
import PLACEMENT from '../../constants/placement.constant';

const MACROS = {
    REFERRER_URL: referrer,
    ADUNIT_PLACEMENT: adUnitPlacement,
    REFERRER_UTM_SOURCE: utmSource,
    REFERRER_UTM_MEDIUM: utmMedium,
    REFERRER_UTM_CAMPAIGN: utmCampaign,
    CUSTOM_ITEM_HOUR_HOST: itemHourHost,
    NUMBER_OF_QUESTIONS: numOfQuestions,
    PLATFORM: platform,
    WIDTH: width,
    HEIGHT: height,
    POSITION: adPosition,
    PARTICLE_ABOVE: particleAbove,
    PARTICLE_BELOW: particleBelow,
    CONTENT_TAGS: itemTags,
    CONTENT_CATEGORIES: itemCategories,
    BRAND_SAFETY_TAGS: brandSafetyTags,
    DOMAIN_WITH_STORY_VERSION: adsModuleNameWithReferrer,
    IMAGE_VIOLENCE: imageViolence,
    IMAGE_MEDICAL: imageMedical,
    IMAGE_ADULT: imageAdult,
};
// reMacros -> /{{(REFERRER_URL|ADUNIT_PLACEMENT|...|DOMAIN_WITH_STORY_VERSION)}}/g
const reMacros = new RegExp(`{{(${Object.keys(MACROS).join('|')})}}`, 'g');

/**
 * Returns the page referrer
 * @return {String}
 */
function referrer() {
    const hostname = EnvironmentService.host;
    return hostname.match(/^www\./) ? hostname.substring(4) : hostname;
}

/**
 * Return placement name
 * @param {Object} context - ad object
 * @return {string}
 */
function adUnitPlacement({ adConfig }) {
    return (adConfig && adConfig.tag && adConfig.tag.name) || '';
}

/**
 * Return the utm_source param from the url
 * @return {string}
 */
function utmSource() {
    return getQueryStringValue('utm_source') || (window.utmCookie && window.utmCookie.get('utm_source')) || '';
}

/**
 * Return the utm_medium param from the url
 * @return {string}
 */
function utmMedium() {
    return getQueryStringValue('utm_medium') || (window.utmCookie && window.utmCookie.get('utm_medium')) || '';
}

/**
 * Return the utm_campaign param from the url
 * @return {string}
 */
function utmCampaign() {
    return getQueryStringValue('utm_campaign') || '';
}

/**
 * Return a unique id build from item id, the current hour and the host
 * @return {string}
 */
function itemHourHost() {
    const hoursString = new Date().getUTCHours().toString();
    const itemId = ItemService.id.replace(/-/g, '');
    const shortId = itemId.substring(0, 30);
    const hours = hoursString.length === 1 ? `0${hoursString}` : hoursString;
    const abtestVariation = (abTestService.getAbTestName() || 'na').substring(0, 8);

    return shortId + hours + abtestVariation;
}

/**
 * Returns number of items in current particle, number of particles, if ad is placed not inside a particle
 * @return {Number}
 */
function numOfQuestions({ placement }) {
    return (placement && placement.length) || 0;
}

/**
 * Returns 'W' for desktop, 'M' for all other browsers
 * @return {String}
 */
function platform() {
    return DeviceDetector.isDesktop ? 'W' : 'M';
}

/**
 * Returns width of ad.
 * @returns {Number}
 */
function width() {
    return 300;
}

/**
 * Returns height of ad.
 * @returns {Number}
 */
function height() {
    return 250;
}

/**
 * Returns ad position in current particle and position in story if ad is placed not inside a particle
 * @return {Number}
 */
function adPosition({ placement }) {
    return (placement && placement.index) || 0;
}

/**
 * Returns alias of particle above
 * This macro is relevant for the story placements only
 * @param {Object} context
 * @return {String}
 */
function particleAbove({ placement }) {
    const { index, section, position } = placement;
    if (
        section === PLACEMENT.SECTIONS.STORY
        && position === PLACEMENT.POSITIONS.BETWEEN_PARTICLES
    ) {
        return index === 0
            ? ItemService.getNonExistentParticleAlias()
            : ItemService.getParticleAlias(index - 1);
    }
    return 'N/A';
}

/**
 * Returns alias of particle below
 * This macro is relevant for the story placements only
 * @param {Object} context
 * @return {String}
 */
function particleBelow({ placement }) {
    const { index, length, section, position } = placement;
    if (
        section === PLACEMENT.SECTIONS.STORY
        && position === PLACEMENT.POSITIONS.BETWEEN_PARTICLES
    ) {
        // There is no particle below last placement (which index is either -1 or equal to length)
        return (index === -1 || index === length)
            ? ItemService.getNonExistentParticleAlias()
            : ItemService.getParticleAlias(index);
    }
    return 'N/A';
}

/**
 * Replace macro with the items tags array, comma separated
 * @return {String}
 */
function itemTags() {
    return [].concat(
        ItemService.tags,
        ItemService.campaignTags
    ).join();
}

/**
 * Replace macro with the items categories array, comma separated
 * @return {String}
 */
function itemCategories() {
    return (window.pbCategories || []).join()
        .replace(/'/gm, '&apos;')
        .replace(/"/gm, '&quot;');
}

/**
 * Replace macro BRAND_SAFETY_TAGS with the grapehot tags, comma separated
 * @return {String}
 */
function brandSafetyTags() {
    try {
        const pbAutoTags = window.pbTaggingInfo || window.pbAutoTags;
        const grapeshotTags = pbAutoTags && pbAutoTags.grapeshotTags;
        return (grapeshotTags instanceof Array) ? grapeshotTags.map(t => t.name).join(',') : '';
    } catch (e) {
        return '';
    }
}

/**
 * Replace macro ADS_MODULE_NAME with constant string "playbuzz-ads"
 * @return {String}
 */
function adsModuleNameWithReferrer() {
    return `${referrer()}_playbuzz-ads`;
}

function imageViolence() {
    return getImageSafetyTags().violence || '';
}

function imageAdult() {
    return getImageSafetyTags().adult || '';
}

function imageMedical() {
    return getImageSafetyTags().medical || '';
}

function getImageSafetyTags() {
    return (window.pbTaggingInfo && window.pbTaggingInfo.imageSafety) || {};
}

export default ({ template = '', adConfig, placement }) => (
    template.replace(reMacros, (match, macro) => {
        const macroFn = MACROS[macro];
        return typeof macroFn === 'function' ? macroFn({ adConfig, placement }) : match;
    })
);
