import { generateId } from '../services/common/generateId.service';

export default class AdComponentModel {
    constructor(rawConfig) {
        this.id = generateId();
        this.placementName = rawConfig.placement.mode;
        this.tag = rawConfig.tag.value;
        this.network = rawConfig.tag.name;
    }
}
