import RoxotConfigurationService from '../configuration/roxot-configuration.service';
import HeaderBidderService from './header-bidders.service';

const PUBLISHER_ID = '94166617';

const loadGpt = () => {
    const dfpScript = document.createElement('script');
    dfpScript.src = '//securepubads.g.doubleclick.net/tag/js/gpt.js';
    dfpScript.async = true;

    document.body.appendChild(dfpScript);

    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
};

function defer() {
    const deferred = {};
    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });
    return deferred;
}

const _initDeferrer = defer();
const _beforeEnableServices = [];

const _slots = [];

const GPTInterface = {
    initGptForEmbedded: () => {
        loadGpt();
        window.googletag.cmd.push(() => {
            if (window.roxotEnabled) {
                RoxotConfigurationService.setTargeting(window.googletag);
            }

            _beforeEnableServices.forEach(cb => cb());
            window.googletag.enableServices();
            _initDeferrer.resolve();
        });
    },

    initGptForOO: () => {
        loadGpt();

        window.googletag.cmd.push(() => {
            window.googletag.pubads().set('page_url', window.location.href);

            // Disable initial load in case header bidders are used
            if (HeaderBidderService.isEnabled()) {
                window.googletag.pubads().disableInitialLoad();
                HeaderBidderService.init();
            }

            if (window.roxotEnabled) {
                RoxotConfigurationService.setTargeting(window.googletag);
            }

            _beforeEnableServices.forEach(cb => cb());
            window.googletag.enableServices();
            _initDeferrer.resolve();
        });
    },

    createAd: ({ adunitId, adunitName, dimensions, targeting, setSlotCallback, placementPosition, isFallback, location }) => {
        const fullAdUnit = `/${PUBLISHER_ID}/${adunitName}`;

        const fn = () => {
            window.googletag.cmd.push(() => {
                const adSlot = window.googletag
                    .defineSlot(fullAdUnit, dimensions, adunitId)
                    .addService(window.googletag.pubads().setTargeting('path-name', window.location.pathname));

                Object.keys(targeting).forEach((key) => {
                    adSlot.setTargeting(key, targeting[key]);
                }, this);

                if (setSlotCallback && typeof setSlotCallback === 'function') {
                    setSlotCallback(adSlot);
                }

                if (HeaderBidderService.isEnabled()) {
                    HeaderBidderService.createAd({
                        gptSlot: adSlot,
                        slotDimensions: dimensions,
                        placementPosition,
                        isFallback,
                        location
                    });
                }

                _slots.push(adSlot);
            });
        };

        _initDeferrer.promise.then(fn);
    },

    show: (adunitId) => {
        if (!HeaderBidderService.isEnabled()) {
            _initDeferrer.promise.then(() => {
                window.googletag.cmd.push(() => {
                    if (window.googletag.pubads().isInitialLoadDisabled()) {
                        const slot = GPTInterface.getSlotById(adunitId);
                        window.googletag.pubads().refresh([slot]);
                    } else {
                        window.googletag.display(adunitId);
                    }
                });
            });
        } else if (_slots && adunitId.indexOf('dfp') !== -1) {
            const slot = GPTInterface.getSlotById(adunitId);

            if (slot) {
                HeaderBidderService.refresh([slot]);
            }
        }
    },

    refresh: (slotsToRefresh) => {
        const slots = Array.isArray(slotsToRefresh) ? slotsToRefresh : [slotsToRefresh];

        if (window.roxotEnabled) {
            RoxotConfigurationService.setTargeting(window.googletag);
        }

        if (HeaderBidderService.isEnabled()) {
            HeaderBidderService.refresh(slots);
        } else {
            window.googletag.pubads().refresh(slots);
        }
    },

    getGoogleTagInstance: () => window.googletag,

    runBeforeEnableServices: (cb) => {
        if (!window.googletag || !window.googletag.pubadsReady) {
            _beforeEnableServices.push(cb);
        } else {
            cb();
        }
    },

    getSlotById: adunitId => _slots.find(s => s.getSlotId().getDomId() === adunitId),

    onSlotRenderEnded(slot) {
        return new Promise((resolve) => {
            window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
                if (event.slot.getSlotId().getDomId() === slot.getSlotId().getDomId()) {
                    resolve(event);
                }
            });
        });
    }
};

export default GPTInterface;
