import { loadScript } from '@playbuzz/script-loader';
import { loadScriptUncached } from '../common/uncached-script-loader.service';
import EnvironmentService from '../common/environment.service';

export default class StreamService {
    static createStreamElement(playerId) {
        const pbMcdElement = document.createElement('pb-mcd');
        pbMcdElement.style.display = 'block';
        pbMcdElement.setAttribute('embed-id', playerId);
        pbMcdElement.setAttribute('subid-function', 'getUtmMedium');
        return pbMcdElement;
    }

    static loadStreamScript(options, outsideOfEmbed = false) {
        const src = this._buildStreamScriptUrl(options);
        if (outsideOfEmbed) {
            const context = EnvironmentService.context.document.body;
            return loadScriptUncached({ src, context });
        }
        return loadScript({ src });
    }

    static isStreamElementEmbedded(context) {
        if (typeof context === 'object') {
            return this._isStreamFoundInObject(context);
        }

        if (typeof context === 'string') {
            return this._isStreamFoundInString(context);
        }

        return null;
    }

    static get CustomEventName() {
        return 'pbStreamEvent';
    }

    static get CustomEventsEnum() {
        return {
            STREAM_LOAD: 'pb.stream.load',
            AD_LOADED: 'pb.stream.ad-loaded',
            AD_IMPRESSION: 'pb.stream.ad-impression',
            AD_SKIPPED: 'pb.stream.ad-skipped',
            AD_COMPLETED: 'pb.stream.ad-complete',
            AD_ERROR: 'pb.stream.ad-error'
        };
    }

    static _buildStreamScriptUrl(options) {
        const { embedId, videoId, itemId } = options;
        let url = 'https://stg-mcd-sdk.playbuzz.com/embed/sdk.js?';

        if (EnvironmentService.isProd) {
            url = 'https://mcd-sdk.playbuzz.com/embed/sdk.js?';
        }

        url += `embedId=${embedId}`;

        if (videoId) {
            url += `&videoId=${videoId}`;
        }

        if (itemId) {
            url += `&itemId=${itemId}`;
        }

        return url;
    }

    static isStreamConfig(id) {
        return Boolean(window.pbMCDConfig && window.pbMCDConfig[id]);
    }

    static getStreamConfig(id) {
        return window.pbMCDConfig ? window.pbMCDConfig[id] : null;
    }

    static isOutstream(playerVersion) {
        return playerVersion && playerVersion.indexOf('outstream') !== -1;
    }

    static _isStreamFoundInObject(context) {
        let list;
        const pageElements = context.document.getElementsByTagName('pb-mcd');
        if (EnvironmentService.isEmbed) {
            list = pageElements;
        } else {
            list = this._getStreamListNodesFromPage(context, pageElements);
        }

        return list.length > 0;
    }

    static _getStreamListNodesFromPage(context, pageElements) {
        const storyElement = context.document.querySelector('.pb-story');
        const storyElements = storyElement ? storyElement.querySelectorAll('pb-mcd') : [];
        const list = this._diffPageStoryNodeElements(pageElements, storyElements);
        return list;
    }

    /**
     * Returns sub-array of nodes from first array,
     * that are _not_ present in second array
     * @param {Node[]} pageElements
     * @param {Node[]} storyElements
     * @return {Node[]}
     */
    static _diffPageStoryNodeElements(pageElements, storyElements) {
        const storyArr = [].slice.call(storyElements);
        const pageArr = [].slice.call(pageElements);
        return pageArr.filter(pageEl => !this._includesElement(storyArr, pageEl));
    }

    /**
     * Check that array of nodes includes target node
     * by node equality (<Node>.isEqualNode(<Node>))
     * @param {Node[]} array - array of nodes
     * @param {Node} target - node seach for
     * @return {boolean}
     */
    static _includesElement(array, target) {
        return array.some(el => target.isEqualNode(el));
    }

    static _isStreamFoundInString(context) {
        return context.indexOf('</pb-mcd>') > -1 || context.indexOf('pbads.mcd.av.player') > -1;
    }
}
