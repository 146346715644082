const AdComponentTypeEnum = {
    DFP: 'dfp',
    CUSTOM_BANNER: 'custom-banner',
    VIDEO: 'video',
    STREAM: 'stream',
    BRAND_UPLIFT_STUDY: 'brand_uplift_study',
    EMPTY: 'empty',
};

const AdTypeEnum = {
    BANNER: 'banner',
    VIDEO: 'video',
    EMPTY: 'empty',
};

export {
    AdComponentTypeEnum,
    AdTypeEnum,
};
