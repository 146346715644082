

class RoxotConfigurationService {
    constructor() {
        this._storage = window.localStorage;
    }

    setTargeting(googletag) {
        googletag.cmd.push(() => {
            googletag.pubads()
                .setTargeting('roxot-group-id', this._getRoxotGroupId())
                .setTargeting('roxot-sector-id', this._getRoxotSectorId())
                .setTargeting('roxot-deep', this._getRoxotDeep())
                .setTargeting('roxot-event-group-id', this._getRoxotEvent())
                .setTargeting('roxot-event', this._getRoxotEvent())
                .setTargeting('roxot-event-deep', this._getRoxotEvent())
                .setTargeting('roxot-minutes', new Date().getUTCMinutes().toString())
                .setTargeting('roxot-hours', new Date().getUTCHours().toString())
                .setTargeting('roxot-day', new Date().getUTCDay().toString());
        });
    }

    _getRoxotGroupId() {
        return this._readProperty('roxot-gid', 10000);
    }

    _getRoxotSectorId() {
        return this._readProperty('roxot-sid', 100);
    }

    _getRoxotDeep() {
        return this._readProperty('roxot-deep', 100);
    }

    _getRoxotEvent(defaultValue = 100) {
        return `${1 + Math.floor(Math.random() * defaultValue)}`;
    }

    _readProperty(propName, defaultValue) {
        try {
            let result = this._storage.getItem(propName);

            if (result === null) {
                result = this._getRoxotEvent(defaultValue);
                this._storage.setItem(propName, result);
            }

            return `${result}`;
        } catch (e) {
            return `${defaultValue + 1}`;
        }
    }
}

export default new RoxotConfigurationService();
