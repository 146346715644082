import { setDeviceInfo, isDesktop as isDeviceDesktop, isTablet, isMobile, isWindows, isMac, isAndroid, isIOS } from '@playbuzz/device-detector';
import { DEVICE, OS } from '../../constants/platform.constant';

class DeviceDetector {
    constructor() {
        setDeviceInfo(window.pbDeviceInfo);
    }

    get isDesktop() {
        return isDeviceDesktop();
    }

    get device() {
        if (isDeviceDesktop()) {
            return DEVICE.DESKTOP;
        }

        if (isMobile() || isTablet()) {
            return DEVICE.MOBILE;
        }

        return DEVICE.OTHER;
    }

    get os() {
        let os;

        if (isWindows()) {
            os = OS.WINDOWS;
        } else if (isMac()) {
            os = OS.MAC_OS;
        } else if (isAndroid()) {
            os = OS.ANDROID;
        } else if (isIOS()) {
            os = OS.IOS;
        } else {
            os = OS.OTHER;
        }

        return os;
    }
}

export default new DeviceDetector();
