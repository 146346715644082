import monetizationTools from './monetization-tools.service';

class Logger {
    constructor() {
        this._determineLogLevel();
    }

    debug(...data) {
        if (this._logLevel === LOG_LEVELS.DEBUG) {
            window.console.log(...data);
        }

        monetizationTools.sendLog('debug', ...data);
    }

    log(...data) {
        if (this._logLevel <= LOG_LEVELS.LOG) {
            window.console.log(...data);
        }

        monetizationTools.sendLog('info', ...data);
    }

    error(...data) {
        if (this._logLevel <= LOG_LEVELS.ERROR) {
            window.console.error(...data);
        }

        monetizationTools.sendLog('error', ...data);
    }

    _determineLogLevel() {
        const url = window.location.href;

        if (url.indexOf('ads-log-level=debug') !== -1) {
            this._logLevel = LOG_LEVELS.DEBUG;
        } else if (url.indexOf('ads-log-level=log') !== -1) {
            this._logLevel = LOG_LEVELS.LOG;
        } else if (url.indexOf('ads-log-level=error') !== -1) {
            this._logLevel = LOG_LEVELS.ERROR;
        } else {
            this._logLevel = LOG_LEVELS.NONE;
        }
    }
}

const LOG_LEVELS = {
    DEBUG: 0,
    LOG: 1,
    ERROR: 2,
    NONE: 3,
};

export default new Logger();
