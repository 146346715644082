import abTestService from '../services/configuration/ab-test.service';
import AbstractAd from './abstract-ad.component';
import { AdTypeEnum } from '../constants/ad-types.constant';
import BannerModel from '../models/banner.model';
import RefreshService from '../services/ad-behavior/refresh.service';
import logger from '../services/common/logger.service';

export default class AbstractBannerComponent extends AbstractAd {
    constructor(container, config, placementData) {
        super(container, placementData, AdTypeEnum.BANNER);

        this._banner = new BannerModel(config, placementData);
        this.adWrapper.setAttribute('data-section', placementData.section);
        this.adWrapper.setAttribute('data-position', placementData.position);
        this.adWrapper.setAttribute('data-index', placementData.index);
        this.adWrapper.setAttribute('data-monetization-path', abTestService.getAbTestName());
    }

    initRefreshes() {
        if (!this._banner.refreshMode) {
            return;
        }

        this._refreshService = new RefreshService(this.refreshAdSlot.bind(this), this._banner.refreshMode);
        this._refreshService.initMinTimer(this._banner.minTime);
        this._refreshService.initTimerRefreshes(this._banner.timerRefreshes);
        this._refreshService.initEngagementNumberRefreshes(this._banner.engagementNumberRefreshes);
        this._refreshService.initEngagementPercentageRefreshes(this._banner.engagementPercentageRefreshes);
    }

    initDuration() {
        if (this._banner.duration) {
            this._adStartTime = +new Date();
            this._timeout = setTimeout(this._resolve, this._banner.duration);
        }
    }

    pause() {
        this._adTimeLeft = this._banner.duration - (new Date() - this._adStartTime);
        clearTimeout(this._timeout);
        this._timeout = null;
    }

    resume() {
        this._timeout = setTimeout(this._resolve, this._adTimeLeft);
    }

    getDuration() {
        return this._banner.duration || null;
    }

    update({ index }) {
        if (!this._refreshService || this._banner.engagementPercentageRefreshes.length === 0) {
            return;
        }

        try {
            const { numOfPlacements } = this._banner;
            // calculating progress in percents
            const prevProgress = this._banner.index / numOfPlacements * 100;
            const nextProgress = index / numOfPlacements * 100;
            this._refreshService.onProgressChange(prevProgress, nextProgress);

            this._banner.index = index;
            this.adWrapper.setAttribute('data-index', index);
        } catch (e) {
            logger.error('Unable to update component', e);
        }
    }

    refreshAdSlot() {
    }
}
