const RefreshConstants = {
    REFRESH_TYPE: {
        TIME: 'timedRefresh',
        ENGAGEMENT_PERCENT: 'engagementRefresh',
        ENGAGEMENT_NUMBER: 'engagementNumberRefresh'
    },
    REFRESH_MODE: {
        MANUAL: 'manual',
        INTERVAL: 'interval',
    }
};

const DefaultValues = {
    BANNER_DURATION: 7000
};

const DistributionTypes = {
    PARTICLE_INTERVAL: 'particle_interval',
    BREAK_EVENLY_INTERVAL: 'break_evenly',
};

export {
    RefreshConstants,
    DistributionTypes,
    DefaultValues,
};
