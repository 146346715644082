export default class Adstack {
    constructor(ads) {
        if (!(ads instanceof Array)) {
            this._isInvalid = true;
            return;
        }

        this._ads = ads;
        this._candidateIndex = 0;
        this._candidatesStack = ads.map((ad, index) => ({
            index,
            cap: ad.cap || window.Infinity,
            alreadyPlaced: 0,
        }));
    }

    /**
     * Gets next ad that should be placed from adstack
     * @returns {Object} ad configuration, null if there are no available ads in adstack
     */
    getNext() {
        if (this._isInvalid || this._candidatesStack.length === 0) {
            return null;
        }

        const index = this._getNextIndex();
        return (index === null) ? null : this._ads[index];
    }

    _getNextIndex() {
        if (this._candidatesStack.length === 0) {
            return null;
        }

        if (this._isCapReached()) {
            this._candidatesStack.splice(this._candidateIndex, 1);

            if (this._candidateIndex >= this._candidatesStack.length) {
                this._candidateIndex = 0;
            }

            return this._getNextIndex();
        }

        const ad = this._candidatesStack[this._candidateIndex];
        ad.alreadyPlaced++;

        if (this._candidateIndex >= this._candidatesStack.length - 1) {
            this._candidateIndex = 0;
        } else {
            this._candidateIndex++;
        }

        return ad.index;
    }

    _isCapReached() {
        const candidate = this._candidatesStack[this._candidateIndex];
        return candidate.alreadyPlaced >= candidate.cap;
    }
}
