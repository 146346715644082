import * as AD_SIZE from '../constants/ad-size.constant';
import PlacementConstants from '../constants/placement.constant';
import MonetizationTools from '../services/common/monetization-tools.service';

export default class AbstractAd {
    constructor(container, placementData, adType) {
        if (!placementData) {
            return;
        }

        this._devtools = new MonetizationTools();

        const adWrapper = document.createElement('div');
        adWrapper.className = 'playbuzz-ad';
        const abConfig = window.abTestConfig ? Object.values(window.abTestConfig)[0] : null;
        if (abConfig && abConfig['enable-ad-placeholder-new'] === 'true') {
            adWrapper.className += ' ad-placeholder';
        }

        const adWrapperInner = document.createElement('div');
        adWrapperInner.className = `playbuzz-ad-inner ${adType} ${placementData.section} ${placementData.position} ${AD_SIZE.FIT_TO_CONTENT}`;

        adWrapper.appendChild(adWrapperInner);
        container.appendChild(adWrapper);

        this.adWrapper = adWrapper;
        this.adWrapperInner = adWrapperInner;
    }

    show() {
        return Promise.resolve();
    }

    /**
     * @desc display the ad wrapper - to be used by the child components
     */
    showAd() {
        this.adWrapper.style.display = 'block';
    }

    hideAd() {
        this.adWrapper.style.display = 'none';
    }

    update() {
    }

    getDuration() {
        return null;
    }

    setInnerWrapperFullWidth() {
        this.adWrapperInner.classList.remove(AD_SIZE.FIT_TO_CONTENT);
        this.adWrapperInner.classList.add(AD_SIZE.FULL_WIDTH);
    }

    setInnerWrapperFitToContent() {
        this.adWrapperInner.classList.remove(AD_SIZE.FULL_WIDTH);
        this.adWrapperInner.classList.add(AD_SIZE.FIT_TO_CONTENT);
    }

    collapseInnerWrapper() {
        this.adWrapperInner.classList.add(AD_SIZE.COLLAPSED);
    }

    expandInnerWrapper() {
        this.adWrapperInner.classList.remove(AD_SIZE.COLLAPSED);
    }

    _getDevToolsDisplayName({ section, position, index }) {
        const { SECTIONS, POSITIONS } = PlacementConstants;
        if (
            (section === SECTIONS.STORY && position === POSITIONS.BETWEEN_PARTICLES)
            || (section === SECTIONS.TRIVIA && position === POSITIONS.BETWEEN_QUESTIONS)
            || (section === SECTIONS.QUIZ && position === POSITIONS.BETWEEN_QUESTIONS)
        ) {
            return `${section}: ${position} ${index} and ${index + 1}`;
        }
        return `${section}: ${position}`;
    }
}
