import { getQueryParam } from './utils';

export default class EnvironmentService {
    static get context() {
        return window.parent;
    }

    static get isEmbed() {
        return window !== window.parent;
    }

    static get isAmp() {
        return EnvironmentService.isEmbed && getQueryParam('implementation') === 'amp';
    }

    static get host() {
        const url = document.location.href;
        const link = document.createElement('a');
        link.href = EnvironmentService.isAmp ? getQueryParam('parentUrl') : url;

        return link.hostname;
    }

    static get isProd() {
        try {
            // get current env, provided by webpack.DefinePlugin
            return !process.env.NODE_ENV || process.env.NODE_ENV === 'production';
        } catch (e) {
            // fallback to production
            return true;
        }
    }

    // playbuzz.com will have this set on the window
    static getPlaybuzzDfpTargeting() {
        return window.dfpTargeting;
    }

    static getIframeElement() {
        return window.frameElement;
    }

    static get isLocal() {
        return this.host === 'localhost';
    }

    static get isPbu() {
        return window.PBU_PLAYER !== undefined;
    }
}
