const toArray = Function.prototype.call.bind(Array.prototype.slice);
const forEach = Function.prototype.call.bind(Array.prototype.forEach);

export function compileHtml(htmlString) {
    const dummy = document.createElement('div');
    const compiled = document.createDocumentFragment();
    dummy.innerHTML = htmlString;
    initScripts(dummy);
    while (dummy.childNodes.length > 0) {
        // node that appends will be automatically
        // removed from its previous parent
        compiled.appendChild(dummy.childNodes[0]);
    }
    return compiled;
}

function initScripts(container) {
    const scripts = container.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i += 1) {
        initScipt(scripts[i]);
    }
}

function initScipt(scriptElement) {
    const { parentElement, nextSibling } = scriptElement;
    const newScript = document.createElement('script');
    parentElement.removeChild(scriptElement);
    setAttrs(newScript, getAttrs(scriptElement));
    if (!scriptElement.src) {
        newScript.innerHTML = scriptElement.innerHTML;
    }
    parentElement.insertBefore(newScript, nextSibling || null);
    return newScript;
}

function getAttrs(el) {
    if (el.attributes) {
        return toArray(el.attributes);
    }
    if (el.getAttributeNames) {
        return el.getAttributeNames().map(name => ({ name, value: el.getAttribute(name) }));
    }
    return [];
}

function setAttrs(el, attrs) {
    forEach(attrs, (attr) => {
        el.setAttribute(attr.name, attr.value);
    });
}
