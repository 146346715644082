import { pbFetch } from '@playbuzz/fetch';
import AbstractAd from './abstract-ad.component';
import AdModel from '../models/ad-component.model';
import { AdComponentTypeEnum, AdTypeEnum } from '../constants/ad-types.constant';
import EnvironmentService from '../services/common/environment.service';
import { compileHtml } from '../services/common/html-utils';
import Logger from '../services/common/logger.service';
import LazyDep from '../services/common/lazy-dep.service';

export default class BrandUpliftStudyAd extends AbstractAd {
    constructor(container, adConfig, placementData) {
        super(container, placementData, AdTypeEnum.BANNER);
        this._model = new AdModel(adConfig);
        this._devtools.sendPlacementDetails({
            id: this._model.id,
            type: AdComponentTypeEnum.BRAND_UPLIFT_STUDY,
            displayName: this._getDevToolsDisplayName(placementData),
            positionType: adConfig.positionType,
            details: {
                'Placement Status': 'Enabled',
                'Ad Fetch Status': 'Not receiving',
                'Overlay Status': 'Not Dsiplayed',
                tag: this._model.tag
            }
        });
    }

    show() {
        const showPromise = new Promise((resolve) => { this._resolve = resolve; });
        this._devtools.sendPlacementDetails({
            details: {
                'Ad Fetch Status': 'Receiving Ad'
            }
        });
        this._fetchStudyInfo()
            .then(this._fetchStudyInfoSuccess.bind(this))
            .catch(this._fetchStudyInfoFailure.bind(this));
        return showPromise;
    }

    _getStudyInfoUrl(studyId, userKey) {
        return EnvironmentService.isProd
            ? `//brand-uplift.playbuzz.com/api/v1/studies/${studyId}/info?tid=${userKey}`
            : `//stg-brand-uplift.playbuzz.com/api/v1/studies/${studyId}/info?tid=${userKey}`;
    }

    _fetchStudyInfo() {
        return LazyDep.get('anonymousId')
            .then(anonymousId => anonymousId || Promise.reject(new Error('PlaybuzzAds: Could not get user anonymous id')))
            .catch((error) => {
                Logger.error(error);
                return Promise.reject(error);
            })
            .then((anonymousId) => {
                const url = this._getStudyInfoUrl(this._model.tag, anonymousId);
                return pbFetch.get(url);
            });
    }

    _fetchStudyInfoSuccess(studyInfo) {
        try {
            const {
                pollItemId, answered, exposed,
                exposedGoal, controlGoal,
                exposedCount, controlCount,
            } = studyInfo;
            const isStudyInProgress = exposed ? exposedCount < exposedGoal : controlCount < controlGoal;
            if (isStudyInProgress && !answered) {
                this._renderAd(pollItemId);
            } else {
                this._refuseAd();
            }
        } catch (err) {
            return Promise.reject(err);
        }
        return studyInfo;
    }

    _fetchStudyInfoFailure(err) {
        this._devtools.sendPlacementDetails({
            details: {
                'Ad Fetch Status': 'Ad Not Received'
            }
        });
        return Promise.reject(err);
    }

    _getItemEmbedCode(itemId) {
        const embedSdkUrl = EnvironmentService.isProd
            ? 'https://embed.playbuzz.com/sdk.js'
            : '//stg-embed.playbuzz.com/sdk.js';
        return `
            <script src="${embedSdkUrl}" id="playbuzz-sdk"></script>
            <div class="playbuzz" data-id="${itemId}" data-show-share="false" data-show-info="false"></div>
        `;
    }

    _renderAd(itemId) {
        const embedCode = this._getItemEmbedCode(itemId);
        const compiledTag = compileHtml(embedCode);
        this._injectContent(compiledTag);
        this.showAd();
        this._devtools.sendPlacementDetails({
            compiledTag: embedCode,
            details: {
                'Ad Fetch Status': 'Ad Received',
                'Overlay Status': 'Displayed'
            }
        });
    }

    _refuseAd() {
        this._resolve();
        this._devtools.sendPlacementDetails({
            details: {
                'Placement Status': 'DISABLED',
                'Ad Fetch Status': 'Ad Received',
                'Overlay Status': 'Not displayed'
            }
        });
    }

    _injectContent(content) {
        this.adWrapperInner.appendChild(content);
    }
}
