// Within this map, keys are strings in a way they set in story (placement)
// and values are how these placements are referenced in configuration (that comes from ads-service)
export default {
    SECTIONS: {
        story: 'story',
        quizSection: 'quiz',
        pollSection: 'poll',
        triviaSection: 'trivia',
        closedTriviaSection: 'closedTrivia',
        videoCreatorSection: 'video',
        unified: 'unified'
    },
    POSITIONS: {
        'between-particles': 'between_particles',
        'between-questions': 'between_questions',
        'in-question': 'in_question',
        'video-creator': 'video_creator',
    },
};
