// This service is responsible for parsing information about story from "pbItem" global variable
import logger from './logger.service';

export default class ItemService {
    /**
     * Returns the global pbItem object
     * @return {Object}
     */
    static get item() {
        return window.pbItem || {};
    }

    /**
     * Returns the item id
     * @return {String}
     */
    static get id() {
        return this.item.id || '';
    }

    static get channelId() {
        return this.item.creator ? this.item.creator.id : '';
    }

    static get sections() {
        return this.item.sections || [];
    }

    static get tags() {
        return this.item.tags || [];
    }

    static get campaignTags() {
        return window.pbCampaignTags || [];
    }

    static getNonExistentParticleAlias() {
        return PARTICLE_ALIAS.None;
    }

    static getSectionType(section) {
        const { type } = section;
        const mediaSectionTypes = ['imageSection', 'videoPlayerSection', 'embedSection'];
        if (mediaSectionTypes.indexOf(type) >= 0) {
            return 'mediaSection';
        }
        return type;
    }

    static getItemTypeByIndex(index) {
        try {
            const section = this.sections[index][0];
            return this.getSectionType(section);
        } catch (e) {
            logger.error(`Couldn't get particle type in index ${index}`);
            return '';
        }
    }

    static getParticleAlias(index) {
        const defaultAlias = PARTICLE_ALIAS.None;
        const storySections = this.item.sections;

        if (storySections && storySections.length && index >= 0) {
            try {
                const section = storySections[index][0]; // for some reason in story every particle is wrapped in array
                if (section) {
                    const sectionType = this.getSectionType(section);
                    if (sectionType === 'mediaSection') {
                        const { mediaType } = section.media;
                        const mediaSectionType = MEDIA_SECTION_TYPE[mediaType];
                        return PARTICLE_ALIAS[mediaSectionType];
                    }
                    return PARTICLE_ALIAS[sectionType];
                }
            } catch (e) {
                logger.error(`Couldn't get proper alias for particle with index of ${index}`);
                return defaultAlias;
            }
        }
        return defaultAlias;
    }
}

const PARTICLE_ALIAS = {
    callToActionSection: 'ctas',
    convoSection: 'cnvs',
    embedSection: 'embs',
    flipCardSection: 'flps',
    imageSection: 'imgs',
    otherSection: 'oths',
    paragraphSection: 'pars',
    pollSection: 'plls',
    quizSection: 'quzs',
    quoteSection: 'quos',
    summarySection: 'sums',
    triviaSection: 'trvs',
    videoCreatorSection: 'vcrs',
    videoPlayerSection: 'vpls',
    closedTriviaSection: 'clos',
    None: 'None',
};

const MEDIA_SECTION_TYPE = {
    image: 'imageSection',
    youtube: 'videoPlayerSection',
    vimeo: 'videoPlayerSection',
    brightcove: 'videoPlayerSection',
    theplatform: 'videoPlayerSection',
    twitter: 'embedSection',
    instagram: 'embedSection',
    soundcloud: 'embedSection',
    playbuzz: 'embedSection',
    googlemaps: 'embedSection',
    iframe: 'embedSection',
    video: 'videoPlayerSection',
};
