import AbstractBannerComponent from './abstract-banner-ad.component';
import BannerModel from '../models/banner.model';
import { AdComponentTypeEnum } from '../constants/ad-types.constant';
import logger from '../services/common/logger.service';
import StreamService from '../services/ad-behavior/stream.service';
import EnvironmentService from '../services/common/environment.service';
import { waitFor } from '../services/common/utils';
import { compileHtml } from '../services/common/html-utils';
import { STREAM_COMPLETE, STREAM_SKIPPED } from '../constants/stream-events.constant';

export default class CustomBannerComponent extends AbstractBannerComponent {
    constructor(container, config, placementData) {
        super(container, config, placementData);

        // remove all that we don't need
        this._container = container;
        this._initialConfig = config;
        this._banner = new BannerModel(config, placementData);

        this._devtools.sendPlacementDetails({
            id: this._banner.id,
            displayName: this._getDevToolsDisplayName(placementData),
            type: AdComponentTypeEnum.CUSTOM_BANNER,
            compiledTag: this._banner.compiledTag,
            positionType: config.positionType,
            details: {
                'Placement Status': 'Enabled',
                'Ad Fetch Status': 'Receiving Ad',
                'Overlay Status': 'Not Displayed',
                tag: this._banner.tag
            }
        });

        // use method as event handler (bind to instance)
        this._streamEventsHandler = this._streamEventsHandler.bind(this);
    }

    show() {
        const domTags = compileHtml(this._banner.compiledTag);
        this._handleSizing(domTags);
        this.adWrapperInner.appendChild(domTags);

        this._handleAdSlot();
        this.initRefreshes();

        const showPromise = new Promise((resolve) => {
            this._resolve = resolve;
        });

        this.initDuration();

        return showPromise;
    }

    refreshAdSlot() {
        logger.debug('refreshing custom banner ad', this._banner);

        // TODO MP: implement refresh for custom banner
    }

    _handleAdSlot() {
        const pbMcdNode = this.adWrapperInner.querySelector('pb-mcd');
        if (pbMcdNode) {
            this._handleStream(pbMcdNode)
                .then((config) => {
                    /* eslint-disable no-else-return */
                    if (StreamService.isOutstream(config.playerVersion)) {
                        return true;
                    } else if (this._isStreamEmbedded(this._banner.compiledTag)) {
                        return false;
                    }
                    /* eslint-enable no-else-return */
                    return true;
                })
                .then((isAd) => {
                    if (isAd) {
                        this.showAd();
                        this._devtools.sendPlacementDetails({
                            details: {
                                'Ad Fetch Status': 'Ad Received',
                                'Overlay Status': 'Displayed'
                            }
                        });
                    } else {
                        this._resolve();
                    }
                });
        } else {
            this.showAd();
            this._devtools.sendPlacementDetails({
                details: {
                    'Ad Fetch Status': 'Ad Received',
                    'Overlay Status': 'Displayed'
                }
            });
        }
    }

    _handleSizing(container) {
        const pbMcd = container.querySelector('pb-mcd');
        const iframe = container.querySelector('iframe');
        const widthAttr = iframe ? iframe.getAttribute('width') : undefined;
        const widthStyle = iframe ? iframe.style.width : undefined;
        const isFullWidth = parseFloat(widthAttr) <= 1 || parseFloat(widthStyle) <= 1;
        if (pbMcd || isFullWidth) {
            this.setInnerWrapperFullWidth();
        } else {
            this.setInnerWrapperFitToContent();
        }
    }

    _handleStream(pbMcdNode) {
        const id = pbMcdNode.getAttribute('embed-id');
        const resolveStreamConfig = (done) => {
            if (StreamService.isStreamConfig(id)) {
                done(StreamService.getStreamConfig(id));
            }
        };
        return waitFor(resolveStreamConfig, { times: 100, delay: 200 })
            .then((config) => {
                pbMcdNode.addEventListener('pbStreamEvent', this._streamEventsHandler);
                return config;
            });
    }

    _isStreamEmbedded(adHtml) {
        const streamContextEl = StreamService.isStreamElementEmbedded(EnvironmentService.context);
        const streamAdEl = StreamService.isStreamElementEmbedded(adHtml);
        return streamContextEl && streamAdEl;
    }

    _streamEventsHandler(event) {
        if (!event.detail) {
            return;
        }
        switch (event.detail.name) {
            case STREAM_COMPLETE:
            case STREAM_SKIPPED:
                this._devtools.sendPlacementDetails({
                    details: {
                        'Overlay Status': 'Not Displayed'
                    }
                });
                this.collapseInnerWrapper();
                break;
            default:
                break;
        }
    }
}
