import logger from '../common/logger.service';

let memo = {};
let usedAds = 0;
let maxAds;

export default class adunitPositionMemoizationService {
    static setMaxAds(count) {
        maxAds = count;
        usedAds = 0;
    }

    static getAdUnit(position) {
        if (!isPositionValid(position)) {
            logger.error('trying to get adunit for not valid position', position);
            return undefined;
        }
        if (maxAds && usedAds >= maxAds) {
            return undefined;
        }

        const key = buildKey(position);
        const adUnit = memo[key];

        if (adUnit) {
            usedAds += 1;
        }
        return adUnit;
    }

    static setAdUnit(position, adUnit) {
        if (!isPositionValid(position)) {
            logger.error('trying to set adunit to invalid position', position);
            return;
        }

        const key = buildKey(position);
        memo[key] = adUnit;
    }

    static setIfNotExists(position, adUnit) {
        if (!isPositionValid(position)) {
            logger.error('trying to set adunit to invalid position', position);
            return;
        }

        const key = buildKey(position);

        if (!memo[key]) {
            memo[key] = adUnit;
        }
    }

    static _clear() {
        memo = {};
        maxAds = undefined;
        usedAds = 0;
    }
}

const buildKey = ({ section, position, index }) => [section, position, index].join('___');

const isPositionValid = position => !!(position && position.section && position.position);
