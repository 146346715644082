import { loadScript } from '@playbuzz/script-loader';
import { isMobile } from '@playbuzz/device-detector';
import { isPostroll } from './utils';

const SLOTS = [];
const BIDDER = 'a9';
let _timeouts;
let initiated = false;
const preInitSlots = [];
let _bidCallback;

/**
 * Amazon controller service
 */
const AmazonService = {
    bidder: BIDDER,

    /**
     * load and init amazon on the page
     */
    init: (config, timeouts, bidCallback) => {
        window.apstag = window.apstag || {};

        _bidCallback = bidCallback;
        _timeouts = timeouts;
        const { src, pubID, adServer } = config;

        return loadScript({ src }).then(() => {
            window.apstag.init({ pubID, adServer });
            initiated = true;
            AmazonService.fetchBids(preInitSlots);
        });
    },

    /**
     * Creates an ad slot in amazon (after its created in gpt)
     * @param gptSlot
     * @param slotDimensions
     * @param isFallback
     * @param location
     */
    createAd: ({ gptSlot, slotDimensions, isFallback, location }) => {
        SLOTS.push({
            slotID: gptSlot.getSlotId().getDomId(),
            slotName: gptSlot.getAdUnitPath(),
            sizes: slotDimensions
        });

        if (!isFallback && !isPostroll(location) && initiated) {
            AmazonService.fetchBids([gptSlot]);
        } else if (!isFallback && !isPostroll(location) && !initiated) {
            preInitSlots.push(gptSlot);
        }
    },

    displayBids: () => {
        if (initiated) {
            window.apstag.setDisplayBids();
        }
    },

    fetchBids: (slots) => {
        const amazonSlots = SLOTS.filter(amazonSlot => slots.find(slot => slot.getSlotId().getDomId() === amazonSlot.slotID));
        if (amazonSlots.length > 0 && initiated) {
            const timeout = isMobile() ? _timeouts.bidderMobile : _timeouts.bidderDesktop;
            window.apstag.fetchBids({ slots: amazonSlots, timeout }, () => _bidCallback(amazonSlots.map(slot => slot.slotID), BIDDER));
        }
    }

};

export default AmazonService;
