import logger from '../common/logger.service';
import ConfigurationService from '../configuration/configuration.service';
import EmptyAd from '../../components/empty-ad.component';
import { AdComponentTypeEnum } from '../../constants/ad-types.constant';
import DfpAd from '../../components/dfp-ad.component';
import CustomBannerAd from '../../components/custom-banner.component';
import VideoAd from '../../components/video-ad.component'; // eslint-disable-line
import StreamAd from '../../components/stream-ad.component';
import BrandUpliftStudyAd from '../../components/brand-uplift-study-ad.component';
import adTypeStrategy from './ad-type-strategy.service';

export default class AdFactory {
    static createAd(placementData) {
        let ad;

        try {
            if (!placementData || !placementData.section || !placementData.position || !placementData.element) {
                logger.error('Placement data is not valid', placementData);
                return new EmptyAd();
            }

            const { adType, adConfig } = ConfigurationService.getConfigForPlacement(placementData);
            logger.debug('got config for placement', placementData, { adType, adConfig });

            ad = createAd({ placementData, adType, adConfig });
        } catch (e) {
            logger.error('error while creating ad', e);
            ad = new EmptyAd();
        }

        return ad;
    }

    static createFallbackAd(placementData, adConfig) {
        const adType = adTypeStrategy.determineAdType(adConfig);
        const ad = createAd({ placementData, adType, adConfig });
        return ad;
    }
}

const createAd = ({ placementData, adType, adConfig }) => {
    const container = placementData.element;
    let ad;

    switch (adType) {
        case AdComponentTypeEnum.DFP:
            ad = new DfpAd(container, adConfig, placementData);
            break;
        case AdComponentTypeEnum.CUSTOM_BANNER:
            ad = new CustomBannerAd(container, adConfig, placementData);
            break;
        case AdComponentTypeEnum.VIDEO:
            ad = new VideoAd(container, adConfig, placementData);
            break;
        case AdComponentTypeEnum.STREAM:
            ad = new StreamAd(container, adConfig, placementData);
            break;
        case AdComponentTypeEnum.BRAND_UPLIFT_STUDY:
            ad = new BrandUpliftStudyAd(container, adConfig, placementData);
            break;
        case AdComponentTypeEnum.EMPTY:
            ad = new EmptyAd(container, adConfig, placementData);
            break;
        default:
            ad = new EmptyAd(container, adConfig, placementData);
    }

    return ad;
};
