import PlacementConstant from '../constants/placement.constant';
import { DefaultValues } from '../constants/configuration.constant';
import AdComponentModel from './ad-component.model';
import applyMacros from '../services/ad-creation/macros.service';

export default class BannerModel extends AdComponentModel {
    constructor(rawConfig, placementData) {
        super(rawConfig, placementData);

        Object.assign(this, {
            type: 'banner',
            placement: placementData,
            duration: rawConfig.tag.duration ? (rawConfig.tag.duration * 1000) : null,
            refreshMode: rawConfig.refreshes.length > 0 ? rawConfig.refreshes[0].mode : '',
            timerRefreshes: (rawConfig.refreshes || [])
                .map(refreshConfig => refreshConfig.timerInterval)
                .filter(refreshConfig => refreshConfig),
            engagementNumberRefreshes: (rawConfig.refreshes || [])
                .filter(refreshConfig => refreshConfig.engagementNumber)
                .map(refreshConfig => refreshConfig.engagementInterval)
                .filter(refreshConfig => refreshConfig),
            engagementPercentageRefreshes: (rawConfig.refreshes || [])
                .filter(refreshConfig => !refreshConfig.engagementNumber)
                .map(refreshConfig => refreshConfig.engagementInterval)
                .filter(refreshConfig => refreshConfig),
            minTime: (rawConfig.refreshes || [])
                .map(refreshConfig => refreshConfig.minTime)
                .filter(refreshConfig => refreshConfig),
            compiledTag: applyMacros({
                template: this.tag,
                placement: placementData,
                adConfig: rawConfig
            }),
        });

        // between questions placement for quiz and closed trivia should always have duration
        if (
            placementData
            && [
                PlacementConstant.SECTIONS.QUIZ,
                PlacementConstant.SECTIONS.POLL,
                PlacementConstant.SECTIONS.CLOSED_TRIVIA,
                PlacementConstant.SECTIONS.UNIFIED
            ].indexOf(placementData.section) !== -1
            && (
                placementData.position === PlacementConstant.POSITIONS.BETWEEN_QUESTIONS
                || placementData.position === PlacementConstant.POSITIONS.BETWEEN_PARTICLES
            )) {
            this.duration = this.duration || DefaultValues.BANNER_DURATION;
        }
    }

    get numOfPlacements() {
        return this.placement.length || 1;
    }

    get index() {
        return this.placement.index || 0;
    }

    set index(newIndex) {
        this.placement.index = newIndex;
    }
}
