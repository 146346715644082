// this service's purpose is for a actions that should be done when module is being initialized
import EngagementTrackingService from './common/engagement-tracking.service';
import GPTInterface from './common/gpt.service';
import EnvironmentService from './common/environment.service';

export default class InitializationService {
    static init() {
        if (EnvironmentService.isEmbed || EnvironmentService.isLocal || EnvironmentService.isPbu) { // pb.com explicitly inits gpt
            GPTInterface.initGptForEmbedded();
        }
        EngagementTrackingService.init();
    }
}
