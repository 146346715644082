import AbstractAd from './abstract-ad.component';

// This class is intended to be used when there is no ad for placement or ad creation encountered an error
export default class EmptyAdComponent extends AbstractAd {
    constructor(container, config, placementData) {
        super(container, config, placementData);

        this.isEmpty = true;
    }
}
