import { EventNames } from '@playbuzz/story-events/index';
import logger from './logger.service';

export default class EngagementTrackingService {
    static init() {
        this.numOfEngagements = 0;
        this._initEngagementListeners();
        this._engagementFns = [];
    }

    static onEngagement(fn) {
        if (typeof fn === 'function') {
            this._engagementFns.push(fn);
        } else {
            logger.debug('onEngagement() callback should be a function');
        }
    }

    static _onEngagementDetected() {
        this.numOfEngagements++;
        this._engagementFns.forEach((fn) => {
            if (typeof fn === 'function') {
                fn(this.numOfEngagements);
            }
        });
    }

    static _initEngagementListeners() {
        document.body.addEventListener('pbItemEvent', (event) => {
            const eventName = event && event.detail && event.detail.name;

            if (!eventName) {
                return;
            }

            if ([EventNames.SECTION.QUIZ_RESULT, EventNames.SECTION.QUIZ_QUESTION_ANSWERED,
                EventNames.SECTION.QUIZ_START, EventNames.SECTION.TRIVIA_QUESTION_ANSWERED].indexOf(eventName) !== -1) {
                this._onEngagementDetected();
            }
        });
    }
}
