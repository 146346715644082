import AdFactory from './ad-factory.service';
import logger from '../common/logger.service';

const initDeferredAds = () => {
    const { PlaybuzzAd } = window;

    if (!PlaybuzzAd.deferredAds) {
        return;
    }

    logger.log('initializing deferred ads');

    Object.keys(PlaybuzzAd.deferredAds).forEach((key) => {
        const adConfig = PlaybuzzAd.deferredAds[key];

        logger.debug('creating deferred ad for placement:', ad);
        const ad = AdFactory.createAd(adConfig.placementConfig);

        adConfig.onUpdate = (newParams) => {
            ad.update(newParams);
        };

        adConfig.showPromise
            .then(() => {
                logger.debug('showing deferred ad:', ad);
                return ad.show();
            })
            .then((data) => {
                logger.debug('resolving deferred ad:', ad);
                return adConfig.completionResolver(data);
            });
    });
};

export { initDeferredAds };
