import AdComponentModel from './ad-component.model';

export default class VideoModel extends AdComponentModel {
    constructor(rawConfig, placementData) {
        super(rawConfig, placementData);

        Object.assign(this, {
            type: 'video',
            placement: placementData,
            timeout: (rawConfig.tag.timeout || 7) * 1000,
            preload: rawConfig.tag.preload,
            tag: rawConfig.tag.videoPlayerId || rawConfig.tag.value,
            positionType: rawConfig.positionType
        });
    }
}
