export { getQueryParam, isInteger, waitFor, isPostroll };

/**
 * Extracts query parameter value by it's name. By default, uses current location url.
 * @param {String} paramName Name of parameter to search in query string
 * @param {String} url Optional. Specify if query parameter should be extracted from some specific url
 * @returns {String} value of query parameter
 */
function getQueryParam(paramName, url = window.location.href) {
    let urlSearchString = url.split('?')[1];

    if (!urlSearchString) {
        return undefined;
    }

    if (urlSearchString.indexOf('?') === 0) {
        urlSearchString = urlSearchString.substr(1);
    }

    const urlParams = urlSearchString.split('&').reduce((params, stringParam) => {
        const newParam = stringParam.split('=');
        params[newParam[0]] = decodeURIComponent(newParam[1]);

        return params;
    }, {});

    return urlParams[paramName];
}

function isInteger(val) {
    const isIntegerFunc = Number.isInteger || function isIntegerPolyfill() {
        return typeof val === 'number'
            && Math.floor(val) === val;
    };

    return isIntegerFunc(val);
}

/**
 * @callback waitForExecutor - the same as Promise executor
 * @param {function} [done]
 * @param {function} [fail]
 */

/**
 * Repeatedly and asyncronously runs function passed as param
 * @param {waitForExecutor} executor
 * @param {Object} options
 * @prop {number} [delay] - delay between executor invocations
 * @prop {number} [times] - limit of invocations count
 * @prop {boolean} [suppressError] - pass true to ignore exceptions,
 *                                   else will be stopped on first exception
 * @return {Promise}
 */
function waitFor(
    executor,
    { delay = 0, times = 10, suppressError = false } = {}
) {
    let timeout = null;
    let stopped = false;
    const promise = new Promise((resolve, reject) => {
        let runs = 0;
        const invokeExecutor = () => {
            try {
                executor(resolve, reject);
            } catch (err) {
                if (!suppressError) {
                    reject(err);
                }
            }
            runs += 1;
            if (!stopped && runs < times) {
                timeout = setTimeout(invokeExecutor, delay);
            } else {
                reject();
            }
        };
        timeout = setTimeout(invokeExecutor, delay);
    });
    const cleanup = () => {
        clearTimeout(timeout);
        timeout = null;
        stopped = true;
    };
    promise.then(cleanup, cleanup);
    return promise;
}

/**
 * Gets an ad location and checks if its a postroll
 * @param adLocation
 * @return {Boolean}
 */
function isPostroll(adLocation) {
    return adLocation && adLocation === -1;
}
