import logger from '../common/logger.service';
import ItemService from '../common/item.service';

const reContentTag = /^pbp(\d+)$/;

class ContentTagsService {
    constructor() {
        this._positions = [];
        this.getPositionsFromTags(ItemService.tags);
        this._lastPosition = this._positions.length > 0 ? Math.max(...this._positions) : null;
    }

    /**
     * @desc get a position index from pbp tag
     * @param {String[]} itemTags
     * @return {Number[]}
     */
    getPositionsFromTags(itemTags) {
        try {
            this._positions = itemTags.reduce((pos, tag) => {
                if (reContentTag.test(tag)) {
                    pos.push(parseInt(RegExp.$1, 10));
                }
                return pos;
            }, []);
        } catch (ex) {
            logger.error('exception in ContentTagsService._getPositionsFromTags');
            this._positions = null;
        }
    }

    /**
     * @desc get a random position according to number of particles
     * @return {number}
     */
    _getRandomPosition() {
        const numSections = ItemService.sections ? ItemService.sections.length : 10;
        const position = Math.floor(Math.random() * numSections) + 1;
        return position;
    }

    /**
     * @desc get a position by tags and if there is pbp tag, get by default (random or fixed)
     * @param {Object} adUnitTag - the ad tag object
     * @return {null|number|*|Number}
     */
    _getFallbackPosition(adUnitTag) {
        let fallbackPosition = null;
        if (adUnitTag.fallbackPlacement === 'random') {
            fallbackPosition = this._getRandomPosition();
            logger.debug(`pbp position random: ${fallbackPosition}`);
        } else if (adUnitTag.fallbackPlacement === 'fixed') {
            fallbackPosition = adUnitTag.fallbackLocation;
            logger.debug(`pbp position fallbackLocation: ${fallbackPosition}`);
        }

        if (typeof fallbackPosition === 'number') {
            this._lastPosition = fallbackPosition;
        } else {
            logger.debug('no pbp position was calculated');
        }

        return fallbackPosition;
    }

    getAdunitForPosition(config, { index }) {
        const adUnit = config.story.placement.contentTags[0];

        if (!adUnit) {
            return null;
        }

        if (this._positions.length > 0) {
            if (this._positions.indexOf(index) !== -1) {
                adUnit.placement.location = index;
                return adUnit;
            }
            return null;
        }

        if (this._getFallbackPosition(adUnit.tag) === index) {
            adUnit.placement.location = index;
            return adUnit;
        }

        return null;
    }

    getCalculatedPosition() {
        return this._lastPosition;
    }
}

export default new ContentTagsService();
