import 'promise-polyfill/src/polyfill';

import ConfigurationService from './services/configuration/configuration.service';
import AdFactory from './services/ad-creation/ad-factory.service';
import logger from './services/common/logger.service';
import monetizationTools from './services/common/monetization-tools.service';
import InitializationService from './services/initialization.service';
import LazyDep from './services/common/lazy-dep.service';
import './main.scss';
import { initDeferredAds } from './services/ad-creation/deferred-ads.service';
import GPTInterface from './services/common/gpt.service';

(function main(w) {
    if (!w.PlaybuzzAd) {
        logger.error('Couldn\'t initialize Playbuzz-Ads-Module. PlaybuzzAd is not defined');
        return;
    }

    w.PlaybuzzAd.module = {
        setConfig: (config) => {
            logger.log('setting configuration', config);
            monetizationTools.sendGeneralDetails(config);
            ConfigurationService.setConfig(config);
            initDeferredAds();
        },
        provide: LazyDep.provide,
        createAd: (placementData) => {
            logger.log('creating ad for placement:', placementData);
            return AdFactory.createAd(placementData);
        },
        getGptInterface: () => GPTInterface
    };

    InitializationService.init();
}(window));
