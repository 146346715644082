import { isMobile } from '@playbuzz/device-detector';
import { loadScript } from '@playbuzz/script-loader';
import { isPostroll } from './utils';
import logger from './logger.service';

let _bidderSettings = null;
const BIDDER = 'prebid';
let _config = null;
let _bidCallback;
const BIDS_CACHE = new Map();

/**
 * Creates a prebid slot
 * @param gptSlot
 * @param placementPosition
 * @param slotDimensions
 * @return {Object} - prebid slot object
 */
function createPrebidSlot(gptSlot, placementPosition, slotDimensions) {
    const platform = isMobile() ? 'mobile' : 'desktop';
    const biddersConfig = _config.bidders.filter(bidder => bidder.placements[platform].indexOf('all') >= 0
        || bidder.placements[platform].indexOf(placementPosition) >= 0);
    const adUnitCode = gptSlot.getAdUnitPath();

    return {
        code: adUnitCode,
        mediaTypes: { banner: { sizes: slotDimensions } },
        domId: gptSlot.getSlotElementId(),
        bids: biddersConfig
            // eslint-disable-next-line no-confusing-arrow
            .filter(bidder => bidder.bidder === 'exco' ? adUnitCode.includes('LEFT') === false : true)
            .map(bidder => Object.assign(
                {},
                bidder,
                { maxBidderCalls: bidder.maxBidderCalls ? parseInt(bidder.maxBidderCalls, 10) : -1 }
            ))
    };
}

/**
 * Creates a cpm adjustment setting object for prebid
 * @param bidders - bidders to adjust
 * @return {Object} - settings object
 */
function createAdjustments(bidders) {
    const bidderSettings = {};
    bidders.forEach((bidder) => {
        bidderSettings[bidder.bidder] = {
            bidCpmAdjustment: (bidCpm, bid) => {
                try {
                    logger.debug(`bidder adjustment on: ${bid.bidderCode}`);
                    return bidCpm * parseFloat(bidder.adjustment);
                } catch (e) {
                    logger.error(`bidder adjustment error: ${e}`);
                    return 1;
                }
            }
        };
    });

    return bidderSettings;
}

/**
 * Return an array of bidder that require cpm adjustments
 * This is where we will add more setting prebid parameters in the future
 * @return {Array / null}
 */
function getBidderSettings() {
    const { bidders } = _config;
    const adjustmentBidders = [];
    if (bidders) {
        bidders.forEach((bidder) => {
            if (bidder.adjustment) {
                adjustmentBidders.push(bidder);
            }
        });
    }
    return adjustmentBidders.length ? createAdjustments(adjustmentBidders) : null;
}

/**
 * Removes bidder that completed their call cap
 */
function updateAdUnitBidders(adUnit) {
    if (adUnit && adUnit.bids && adUnit.bids.length) {
        const filteredBids = adUnit.bids.filter((bid) => {
            if (bid.maxBidderCalls && bid.maxBidderCalls > 0) {
                bid.maxBidderCalls--;
            }
            return bid.maxBidderCalls !== 0;
        });
        adUnit.bids = filteredBids;
    }
}

/**
 * Decrement call remaining for each bidder and remove completed bidders
 */
function decrementBidderCalls(requestedAdUnits) {
    requestedAdUnits.forEach((adUnit) => {
        const { adUnits } = window.expbjs;
        const targetAd = adUnits.filter(expbjsAd => expbjsAd.code === adUnit.code && expbjsAd.domId === adUnit.domId)[0];
        updateAdUnitBidders(targetAd);
    });
}

function createFrame(id) {
    const frame = document.createElement('iframe');
    const target = document.getElementById(id);
    frame.id = `${id}-frame`;
    frame.setAttribute('scrolling', 'no');
    frame.setAttribute('frameBorder', '0');
    // frame.style.border = '1px solid #e4e4e4';
    // frame.style.border = '1px solid blue';

    target.appendChild(frame);
    target.style.display = 'initial';

    return frame.contentWindow.document;
}

function getHighestBid(bids) {
    return bids.sort((a, b) => b.cpm - a.cpm)[0];
}

/**
 * Prebid controller service
 */
const PrebidService = {
    bidder: BIDDER,

    /**
     * load and init prebid on the page
     */
    init: (config, timeouts, bidCallback) => {
        window.expbjs = window.expbjs || {};
        window.expbjs.que = window.expbjs.que || [];

        _config = config;
        _bidCallback = bidCallback;

        _bidderSettings = getBidderSettings();
        return loadScript({
            // src: config.sdkUrl
            // src: 'https://cdn.ex.co/prebid/production/test/exco-banner/880bebb-2025-02-14-13-41-36/expb.js'
            src: 'https://cdn.ex.co/prebid/test/prebid.js',
            // src: 'http://localhost:9090/expb.js'
        }).then(() => {
            window.expbjs.que.push(() => {
                window.expbjs.setConfig({
                    priceGranularity: { buckets: config.priceGranularity },
                    bidderTimeout: isMobile() ? timeouts.bidderMobile : timeouts.bidderDesktop
                });
            });
        });
    },

    renderAd: (id, bids) => {
        const bid = getHighestBid(bids);
        const frameDoc = createFrame(id);

        window.expbjs = window.expbjs || {};
        window.expbjs.que = window.expbjs.que || [];
        window.expbjs.que.push(() => {
            window.expbjs.renderAd(frameDoc, bid.adId);

            frameDoc.body.style.padding = 0;
            frameDoc.body.style.margin = 0;
        });
    },

    /**
     * Creates an ad slot in prebid (after its created in gpt)
     * @param gptSlot
     * @param slotDimensions
     * @param placementPosition
     * @param isFallback
     * @param location
     */
    createAd: ({ gptSlot, slotDimensions, placementPosition, isFallback, location }) => {
        const prebidSlot = createPrebidSlot(gptSlot, placementPosition, slotDimensions);

        window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
            if (event.slot.getSlotElementId() === gptSlot.getSlotElementId()) {
                if (event.isEmpty) {
                    const path = gptSlot.getAdUnitPath();
                    const bids = BIDS_CACHE.get(path);

                    if (bids) {
                        PrebidService.renderAd(gptSlot.getSlotElementId(), bids);
                    }
                }
            }
        });

        window.expbjs = window.expbjs || {};
        window.expbjs.que = window.expbjs.que || [];

        window.expbjs.que.push(() => {
            window.expbjs.addAdUnits(prebidSlot);
            if (_bidderSettings) {
                window.expbjs.bidderSettings = _bidderSettings;
            }
            if (!isFallback && !isPostroll(location)) {
                PrebidService.requestBids([gptSlot]);
            }
        });
    },

    setRequestSent: () => {
        window.expbjs = window.expbjs || {};
        window.expbjs.adserverRequestSent = true;
    },

    /**
     * Set both prebid targeting and gpt service level targeting for returned bids
     */
    setTargeting: (slotId, cb) => {
        window.expbjs = window.expbjs || {};
        window.expbjs.que.push(() => {
            window.expbjs.setTargetingForGPTAsync(slotId); // sets the prebid targeting

            if (cb) {
                cb();
            }
        });
    },

    cleanup: (slot) => {
        const path = slot.getAdUnitPath();
        const id = slot.getSlotElementId();
        const frame = document.getElementById(`${id}-frame`);

        if (frame) {
            frame.parentNode.style.removeProperty('display');
            frame.parentNode.removeChild(frame);
        }

        BIDS_CACHE.delete(path);
    },

    requestBids:
        (slots) => {
            const adUnitCodes = slots.map(slot => slot.getAdUnitPath());

            slots.forEach(c => PrebidService.cleanup(c));

            window.expbjs = window.expbjs || {};
            window.expbjs.que = window.expbjs.que || [];

            window.expbjs.que.push(() => {
                window.expbjs.requestBids({
                    adUnitCodes,
                    bidsBackHandler: (bids) => {
                        const _bids = Object.keys(bids);

                        if (_bids.length) {
                            _bids.forEach(b => BIDS_CACHE.set(b, bids[b].bids));
                        }

                        _bidCallback(slots.map(slot => slot.getSlotId().getDomId()), BIDDER);
                    }
                });
                decrementBidderCalls(slots.map(slot => ({
                    domId: slot.getSlotElementId(),
                    code: slot.getAdUnitPath()
                })));
            });
        }
};

export default PrebidService;
