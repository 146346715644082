import { ViewportMonitor } from '@playbuzz/viewport-monitor';
import { AdComponentTypeEnum } from '../constants/ad-types.constant';
import AbstractBannerComponent from './abstract-banner-ad.component';
import logger from '../services/common/logger.service';
import StreamService from '../services/ad-behavior/stream.service';
import EnvironmentService from '../services/common/environment.service';
import AnalyticsEventsService from '../services/analytics/analytics-events.service';
import GPTInterface from '../services/common/gpt.service';

let counter = 0;

export default class DfpAd extends AbstractBannerComponent {
    constructor(container, config, placementData) {
        super(container, config, placementData);
        logger.debug('building DFP ad', container, config);

        this._container = container;
        this._initialConfig = config;
        this._placementData = placementData;

        this._rendered = false;
        this._isBE = false;
        this._targeting = {};
        this._dimensions = [];
        this._adUnit = '';
        this._adSlot = null;
        this._tagId = null;

        this.showSlotResultEnum = {
            EMPTY_SLOT: 0,
            IS_STREAM_EMBEDDED: 1,
            SHOW_SLOT: 2,
        };

        this._devtools.sendPlacementDetails({
            id: this._banner.id,
            displayName: this._getDevToolsDisplayName(placementData),
            type: AdComponentTypeEnum.DFP,
            compiledTag: this._banner.compiledTag,
            positionType: config.positionType,
            details: {
                'Placement Status': 'Enabled',
                'Ad Fetch Status': 'Receiving Ad',
                'Overlay Status': 'Not Displayed',
                tag: this._banner.tag
            }
        });

        this.createAdDefered();

        AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.AD_ENABLED, this._banner);

        this._setSlot = this._setSlot.bind(this);
        this._parseMetadataAndAppendTag();
        this._initAdSlot();
    }

    render() {
        return new Promise((resolve) => {
            GPTInterface.show(this._tagId);
            GPTInterface.onSlotRenderEnded(this._adSlot)
                .then((event) => {
                    this._rendered = true;
                    this._isEmpty = event.isEmpty;
                    resolve({ isEmpty: event.isEmpty });
                });
        });
    }

    show() {
        const me = this;
        this.initRefreshes();

        AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.AD_INVOKE, this._banner);

        if (this._rendered === false) {
            GPTInterface.show(me._tagId);
        }

        return new Promise((resolve) => {
            this.adDeferred.promise.then(resolve);
            if (this._isEmpty) {
                this._resolve();
            } else {
                this.initDuration();
            }
        });
    }

    createAdDefered() {
        this.adDeferred = {
            promise: new Promise((resolve) => {
                this._resolve = () => {
                    resolve();
                    this.createAdDefered();
                };
            })
        };
    }

    _checkBrandedElement() {
        if (!this._isBE) {
            // Only Branded Element has this script
            this._isBE = this._container.querySelector('#playbuzz-creative-sdk') !== null;
        }
        return this._isBE;
    }

    _checkIfAdInView() {
        try {
            const viewportMonitor = new ViewportMonitor();
            return viewportMonitor.isInViewport(this._container);
        } catch (e) {
            logger.error('Failed to determine if ad in view', e);
            return true;
        }
    }

    refresh() {
        AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.AD_INVOKE, this._banner);

        this.refreshAdSlot();

        return new Promise((resolve) => {
            GPTInterface.onSlotRenderEnded(this._adSlot)
                .then((event) => {
                    this._rendered = true;
                    this._isEmpty = event.isEmpty;

                    if (this._isEmpty) {
                        resolve({ isEmpty: event.isEmpty });
                    } else {
                        this.adDeferred.promise.then(resolve);
                        this.initDuration();
                    }
                });
        });
    }

    refreshAdSlot() {
        if (this._checkBrandedElement()) {
            return;
        }

        if (window && window.inViewAdRefreshEnabled && !this._checkIfAdInView()) {
            return;
        }

        logger.debug('refreshing dfp ad', this._banner);
        GPTInterface.refresh([this._adSlot]);
    }

    static _generateId() {
        return `dfp-wrapper-${counter++}`;
    }

    _parseMetadataAndAppendTag() {
        const dummyContainer = document.createElement('div');
        dummyContainer.innerHTML = this._banner.compiledTag;
        const domTag = dummyContainer.firstChild;

        this._parseDimensions(domTag);
        this._parseTargeting(domTag);
        this._parseAdunit(domTag);
        this._setId(domTag);

        this.adWrapperInner.appendChild(domTag);
    }

    _parseDimensions({ dataset } = {}) {
        try {
            const { dimensions } = dataset;
            this._dimensions = dimensions.split(',').map(dimension => dimension.split('x').map(Number));
        } catch (e) {
            logger.error('Parsing dimensions for DFP ad failed', e, this._initialConfig);
        }
    }

    _parseTargeting(domTag) {
        try {
            const { targeting } = domTag.dataset;
            if (targeting) {
                this._targeting = JSON.parse(targeting);
            }
        } catch (e) {
            logger.error('Parsing targeting for DFP ad failed', e, this._initialConfig);
        }
    }

    _parseAdunit(domTag) {
        this._adUnit = domTag.dataset.adunit;
    }

    _setSlot(slot) {
        this._adSlot = slot;
    }

    _initAdSlot() {
        const me = this;
        me._setTargeting();
        me.isFallback = me._initialConfig.fallbackTag && me._initialConfig.tag
            ? me._initialConfig.tag.value === me._initialConfig.fallbackTag.value : false;

        GPTInterface.createAd({
            adunitId: me._tagId,
            adunitName: me._adUnit,
            dimensions: me._dimensions,
            targeting: me._targeting,
            setSlotCallback: me._setSlot,
            placementPosition: me._placementData.position,
            isFallback: me.isFallback,
            location: me._initialConfig && me._initialConfig.placement && me._initialConfig.placement.location
        });

        GPTInterface.runBeforeEnableServices(() => me._bindSlotRenderEndedEvent());
    }

    _setTargeting() {
        const playbuzzDfpTargeting = EnvironmentService.getPlaybuzzDfpTargeting();
        if (playbuzzDfpTargeting) {
            this._targeting = Object.assign(this._targeting, playbuzzDfpTargeting);
        }
    }

    // every dfp container needs to have unique id
    _setId(domTag) {
        this._tagId = DfpAd._generateId();
        domTag.id = this._tagId;
    }

    _bindSlotRenderEndedEvent() {
        const googleTag = GPTInterface.getGoogleTagInstance();
        googleTag.pubads().addEventListener('slotRenderEnded', this._onSlotRenderEndedEvent.bind(this));
        googleTag.pubads().addEventListener('slotOnload', this._handleSlotOnLoad.bind(this));
    }

    _onSlotRenderEndedEvent(event) {
        if (event.slot === this._adSlot) {
            this._handleAdSlot(event);
        }
    }

    _handleSlotOnLoad(event) {
        if (event.slot === this._adSlot) {
            this._devtools.sendPlacementDetails({
                details: {
                    'Ad Fetch Status': event.isEmpty ? 'Ad Empty' : 'Ad Received'
                }
            });
        }
    }

    _handleAdSlot(event) {
        const shouldShowSlotResult = this._shouldShowSlot(event);
        if (shouldShowSlotResult === this.showSlotResultEnum.SHOW_SLOT) {
            // if with of dfp ad unit is 1 pixel then we assume it is a branded element
            if (this._isSlotWidthOnePixel(event)) {
                this.setInnerWrapperFullWidth();
            }
            this.showAd();
            AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.AD_IMPRESSION, this._banner);
            this._devtools.sendPlacementDetails({
                details: {
                    'Overlay Status': 'Displayed'
                }
            });
        } else {
            this._devtools.sendPlacementDetails({
                details: {
                    'Ad Fetch Status': 'Ad Empty',
                    'Overlay Status': 'Not Displayed'
                }
            });

            this._resolve();
        }
    }

    _shouldShowSlot(event) {
        if (this._isSlotEmpty(event)) {
            logger.debug('ad has no fill', this._container, this._initialConfig);
            return this.showSlotResultEnum.EMPTY_SLOT;
        }

        if (this._isStreamEmbedded(event)) {
            logger.debug('ad will be resolved - Direct Stream Exists', this._container, this._initialConfig);
            return this.showSlotResultEnum.IS_STREAM_EMBEDDED;
        }

        return this.showSlotResultEnum.SHOW_SLOT;
    }

    _isSlotEmpty(event) {
        this._isEmpty = event.isEmpty;
        return event.isEmpty;
    }

    _isSlotWidthOnePixel(event) {
        const width = Array.isArray(event.size) ? event.size[0] : NaN;
        return width <= 1;
    }

    /**
     * @desc check if there is a Stream player inside the ad
     * @param {*} event
     * @return {Boolean} is stream embedded
     */
    _isStreamEmbedded(event) {
        const slotHtml = event.slot.getHtml();
        this.streamContextElm = this.streamContextElm || StreamService.isStreamElementEmbedded(EnvironmentService.context);
        const streamSlotElm = StreamService.isStreamElementEmbedded(slotHtml);
        return !!(this.streamContextElm && streamSlotElm);
    }
}
