import logger from '../common/logger.service';
import AbTestService from '../configuration/ab-test.service';

class AnalyticsEventsService {
    constructor() {
        if (window.PlaybuzzAd && window.PlaybuzzAd.analyticsReporterService) {
            this.analyticsReporter = window.PlaybuzzAd.analyticsReporterService;
        } else {
            logger.error('analyticsReporter does not exist on PlaybuzzAd');
        }
    }

    _createEvent(data, error) {
        let resultData = {};

        resultData = {
            monetizationPath: AbTestService.getAbTestName()
        };

        if (data) {
            // this structure is what ads-module excepts
            resultData = Object.assign(resultData, {
                adPlacement: data.placementName,
                adUuid: data.id,
                adType: data.type,
                adNetwork: data.network,
                preload: data.preload,
                adTag: data.compiledTag || data.tag,
                selectedPlayer: data.player,
                duration: data.duration,
            });
        }

        if (error) {
            resultData.error = error;
        }

        return resultData;
    }

    sendEvent(type, data, error) {
        if (!this.analyticsReporter) {
            return;
        }

        const eventData = this._createEvent(data, error);

        this.analyticsReporter.report({
            name: type,
            requiredAttributes: [],
        }, eventData);
    }

    get EventTypeConstant() {
        return {
            AD_ERROR: 'ad_error',
            AD_REJECTED: 'ad_module_rejected',
            PBADS_BLOCKED: 'ad_blocked',
            AD_IMPRESSION: 'ad_impression',
            AD_REQUEST: 'ad_request',
            AD_INVOKE: 'ad_invoke',
            ADS_MODULE_LOADED: 'ad_module_loaded',
            AD_ENABLED: 'ad_enabled',
            AD_STARTED: 'ad_started',
            AD_COMPLETE: 'ad_completed',
            AD_ACTION: 'ad_action',
            AD_PROGRESS: 'ad_progess',
        };
    }
}

export default new AnalyticsEventsService();
