import { RefreshConstants } from '../../constants/configuration.constant';
import EngagementTrackingService from '../common/engagement-tracking.service';

export default class RefreshService {
    constructor(refreshFunction, refreshMode) {
        this._refreshFunction = () => {
            if (this.isTimerCompleted()) {
                this.startMinTimer();
                refreshFunction();
            }
        };
        this._refreshMode = refreshMode;
        this._engagementCues = [];
        this._minTimer = null;
        this._timerTimeouts = {};
        this._minTime = 0;
    }

    /**
     * @desc create refresh intervals/timeout and execute the given refresh function
     * @param {number[]} refreshes - array of refresh time values
     */
    initTimerRefreshes(refreshes = []) {
        refreshes.forEach((refreshInterval) => {
            const interval = Number(refreshInterval);
            if (interval > 0) {
                if (this._refreshMode === RefreshConstants.REFRESH_MODE.INTERVAL) {
                    this._timerTimeouts[interval.toString()] = setInterval(() => this._refreshFunction(), interval * 1000);
                } else {
                    this._timerTimeouts[interval.toString()] = setTimeout(() => this._refreshFunction(), interval * 1000);
                }
            }
        });
    }

    initEngagementPercentageRefreshes(engagementCues = []) {
        if (this._refreshMode === RefreshConstants.REFRESH_MODE.INTERVAL && engagementCues[0]) {
            const interval = engagementCues[0];
            this._engagementCues = [];
            for (let cue = interval; cue < 100; cue += interval) {
                this._engagementCues.push(cue);
            }
        } else {
            this._engagementCues = engagementCues;
        }

        this._engagementCues.sort((first, second) => first.interval - second.interval);
    }

    initEngagementNumberRefreshes(engagementCues = []) {
        const isInterval = this._refreshMode === RefreshConstants.REFRESH_MODE.INTERVAL;
        const engagementInterval = isInterval && engagementCues[0];

        EngagementTrackingService.onEngagement((numOfEngagements) => {
            // isInterval is refresh every x-th engagement
            if (
                isInterval
                && engagementInterval
                && numOfEngagements % engagementInterval === 0
            ) {
                this.restartTimerIntervals();
                this._refreshFunction();
                // if it's not interval refresh only for specific numbers of engagements
            } else if (!isInterval && engagementCues.find(cue => cue === numOfEngagements)) {
                this._refreshFunction();
            }
        });
    }

    initMinTimer(minTime) {
        if (minTime && minTime.length) {
            // eslint-disable-next-line prefer-destructuring
            this._minTime = minTime[0];
            this.startMinTimer();
        }
    }

    startMinTimer() {
        if (this._minTime > 0) {
            this._minTimerCompleted = false;
            this._minTimer = setTimeout(() => {
                this._minTimerCompleted = true;
            }, this._minTime * 1000);
        }
    }

    isTimerCompleted() {
        return !this._minTimer || this._minTimerCompleted;
    }

    onProgressChange(prevProgress, nextProgress) {
        if (this._engagementCues.length > 0 && nextProgress >= this._engagementCues[0]) {
            this.restartTimerIntervals();
            this._engagementCues.splice(0, 1);
            this._refreshFunction();
        }
    }

    restartTimerIntervals() {
        Object.keys(this._timerTimeouts).forEach((timerInterval) => {
            if (this._refreshMode === RefreshConstants.REFRESH_MODE.INTERVAL) {
                clearInterval(this._timerTimeouts[timerInterval]);
            } else {
                clearTimeout(this._timerTimeouts[timerInterval]);
            }
        });
        this.initTimerRefreshes(Object.keys(this._timerTimeouts).map(key => Number(key)));
    }
}
