export default class ContentIntervalDistribution {
    constructor({ numOfPositions = 0, frequency, startFrom = 0, priority = -1 } = {}) {
        this._current = 0;
        this._positions = this._genPositions(
            startFrom,
            frequency || 1,
            numOfPositions,
            priority
        );
    }

    /**
     * Gets next available placement according to configuration
     * @returns {Number} Index of next placement, null if no placements left
     */
    getNextPosition() {
        if (this._current < this._positions.length) {
            return this._positions[this._current++];
        }
        return null;
    }

    _genPositions(startFrom, frequency, posCount, priorityPos) {
        const result = [];
        for (let pos = startFrom; pos < posCount; pos += frequency) {
            if (pos === priorityPos) {
                result.unshift(pos);
            } else {
                result.push(pos);
            }
        }
        return result;
    }
}
