export function loadScriptUncached({ src, context = document.body }) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.src = src;
        context.appendChild(script);

        // Wrap onLoad and onError callback with a promise
        const loadPromise = new Promise((loadResolve, loadReject) => {
            script.onload = () => loadResolve();

            script.onerror = () => loadReject();
        });

        // Wrapped callback
        return loadPromise
            .then(resolve)
            .catch(reject);
    });
}
