/**
 * @callback CondAdstackIteratorPredicate
 * @param {Object} ad - ad to test
 * @return {Boolean}
 */

export default class CondAdstackIterator {
    /**
     * Iterate ads from Adstack conditionally
     * @constructor
     * @param {Object} adstack - Adstack to iterate on
     * @param {number} maxAdstackDepth - maximum ads limit (in case of adstack it endless)
     */
    constructor(adstack, maxAdstackDepth = 3) {
        this._adstack = adstack;
        this._deferredAds = [];
        this._adstackExhausted = false;
        this._maxAdstackDepth = maxAdstackDepth;
    }

    /**
     * Try to return first ad that satisfy condition passed as predicate
     * @param {CondAdstackIteratorPredicate} predicate - check that ad satisfy condition
     * @return {?Object}
     */
    getNextAd(predicate) {
        let ad;
        const deferredAdIndex = findArrayIndex(this._deferredAds, predicate);
        if (deferredAdIndex !== -1) {
            // array destructing used on Array.prototype.splice() results
            // according to {@link https://www.ecma-international.org/ecma-262/6.0/#sec-array.prototype.splice|Array.prototype.slice}
            // "NOTE 1 ... An Array object containing the deleted elements (if any) is returned."
            [ad] = this._deferredAds.splice(deferredAdIndex, 1);
        } else if (!this._adstackExhausted) {
            for (let i = 0; i < this._maxAdstackDepth; i += 1) {
                const adFromStack = this._adstack.getNext();
                if (adFromStack) {
                    if (predicate(adFromStack)) {
                        ad = adFromStack;
                        break;
                    } else {
                        this._deferredAds.push(adFromStack);
                    }
                } else {
                    this._adstackExhausted = true;
                    break;
                }
            }
        }
        return ad || null;
    }

    /**
     * @return {Boolean}
     */
    hasNextAd() {
        return !this._adstackExhausted || this._deferredAds.length > 0;
    }
}

const findArrayIndex = (arr, callback) => {
    for (let i = 0; i < arr.length; i++) {
        if (callback.call(this, arr[i])) {
            return i;
        }
    }
    return -1;
};
