import { isMobile } from '@playbuzz/device-detector';
import PrebidService from './prebid.service';
import AmazonService from './amazon.service';

const BIDDERS = [];

const requestManager = {};

function getConfig() {
    return window.pbSiteAdsConfig ? window.pbSiteAdsConfig.headerBidders : { amazon: {}, prebid: {} };
}

// return true if all bidders have returned
function allBiddersBack(slotId) {
    return BIDDERS.every(bidder => requestManager[slotId][bidder]);
}

// handler for header bidder responses
function headerBidderBack(slotIds, bidder) {
    slotIds.forEach((slotId) => {
        // return early if request to adserver is already sent
        if (requestManager[slotId].adserverRequestSent === true) {
            return;
        }
        // flip bidder back flag
        requestManager[slotId][bidder] = true;

        // if all bidders are back, send the request to the ad server
        if (allBiddersBack(slotId)) {
            sendAdserverRequest([requestManager.slots.find(slot => slot.getSlotId().getDomId() === slotId)]);
        }
    });
}

// actually get ads from GAM
function sendAdserverRequest(slots) {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    slots.forEach((slot) => {
        const slotId = slot.getSlotId().getDomId();

        // return early if request already sent
        if (requestManager[slotId].adserverRequestSent === true) {
            return;
        }

        // flip the boolean that keeps track of whether the adserver request was sent
        requestManager[slotId].adserverRequestSent = true;
        // flip pbjs boolean to signal to pbjs the ad server has already been called
        PrebidService.setRequestSent();

        // flip boolean for adserver request to avoid duplicate requests
        requestManager[slotId].sendAdserverRequest = true;

        PrebidService.setTargeting(slot.getAdUnitPath(), () => {
            // set bid targeting and make ad request to GAM
            window.googletag.cmd.push(() => {
                AmazonService.displayBids();
                resetGptServiceLevelTargeting();
                window.googletag.pubads().refresh([slot]);
            });
        });
    });
}

function resetGptServiceLevelTargeting() {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    const serviceLevelTargeting = window.googletag.pubads().getTargetingKeys();
    serviceLevelTargeting.forEach((param) => {
        const value = window.googletag.pubads().getTargeting(param);
        window.googletag.cmd.push(() => {
            window.googletag.pubads().setTargeting(param, value);
        });
    });
}

function requestBids(slots) {
    const { prebid, amazon } = getConfig();

    // fetch apstag bids
    if (amazon.enabled) {
        AmazonService.fetchBids(slots);
    }

    // request bids from prebid
    if (prebid.enabled) {
        PrebidService.requestBids(slots);
    }
}

const HeaderBidderService = {
    isEnabled: () => (getConfig().amazon.enabled || getConfig().prebid.enabled),

    init: () => {
        const { amazon, prebid, timeouts } = getConfig();
        if (amazon.enabled) {
            BIDDERS.push(AmazonService.bidder);
            AmazonService.init(amazon, timeouts, headerBidderBack);
        }

        if (prebid.enabled) {
            BIDDERS.push(PrebidService.bidder);
            PrebidService.init(prebid, timeouts, headerBidderBack);
        }

        requestManager.slots = [];

        // setTimeout(() => sendAdserverRequest(requestManager.slots), timeouts.failsafe);
    },

    /**
     * Creates an ad slot in all enabled header bidders (after its created in gpt)
     * @param gptSlot
     * @param slotDimensions
     * @param placementPosition
     * @param isFallback
     * @param location
     */
    createAd: ({ gptSlot, slotDimensions, placementPosition, isFallback, location }) => {
        requestManager[gptSlot.getSlotId().getDomId()] = {
            adserverRequestSent: false
        };

        requestManager.slots.push(gptSlot);

        const { prebid, amazon } = getConfig();

        if (prebid.enabled) {
            PrebidService.createAd({ gptSlot, slotDimensions, placementPosition, isFallback, location });
        }

        if (amazon.enabled) {
            AmazonService.createAd({ gptSlot, slotDimensions, isFallback, location });
        }
    },

    refresh: (slots) => {
        // loop through bidder array and add the bidders to the request manager
        slots.forEach((slot) => {
            const slotId = slot.getSlotId().getDomId();
            BIDDERS.forEach((bidder) => {
                requestManager[slotId][bidder] = false;
            });
            requestManager[slotId].adserverRequestSent = false;
            requestManager[slotId].sendAdserverRequest = false;
        });

        requestBids(slots);

        // set timeout to send request to call sendAdserverRequest() after timeout
        // if all bidders haven't returned before then
        const { timeouts } = getConfig();
        const timeout = isMobile() ? timeouts.bidderMobile : timeouts.bidderDesktop;
        window.setTimeout(() => {
            sendAdserverRequest(slots);
        }, timeout);
    }
};

export default HeaderBidderService;
