import { AdComponentTypeEnum } from '../constants/ad-types.constant';
import AdComponentModel from './ad-component.model';
import ItemService from '../services/common/item.service';

export default class StreamModel extends AdComponentModel {
    constructor(rawConfig, placementData) {
        super(rawConfig, placementData);

        Object.assign(this, {
            type: AdComponentTypeEnum.STREAM,
            player: 'stream',
            tag: rawConfig.tag.value.trim(),
            useChannelId: rawConfig.tag.useChannelId,
            itemId: ItemService.id,
            videoId: placementData.sectionId,
            network: rawConfig.tag.name,
            positionType: rawConfig.positionType,
            outsideOfEmbed: rawConfig.tag.outsideOfEmbed,
            placement: placementData
        });
    }
}
