const DEVICE = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
    OTHER: 'other',
};

const OS = {
    WINDOWS: 'windows',
    MAC_OS: 'mac_os',
    IOS: 'ios',
    ANDROID: 'android',
    OTHER: 'other',
};

export { DEVICE, OS };
