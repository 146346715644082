import { AdComponentTypeEnum } from '../../constants/ad-types.constant';
import logger from '../common/logger.service';

export default class adTypeStrategy {
    static determineAdType(adConfig) {
        try {
            if (adConfig && adConfig.tag) {
                switch (adConfig.tag.type) {
                    case 'display':
                        return determineBannerType(adConfig);
                    case 'stream':
                        return AdComponentTypeEnum.STREAM;
                    case 'video':
                        return AdComponentTypeEnum.VIDEO;
                    case 'brand_uplift_study':
                        return AdComponentTypeEnum.BRAND_UPLIFT_STUDY;
                    default:
                        return AdComponentTypeEnum.EMPTY;
                }
            }
        } catch (e) {
            logger.error('Couldn\'t determine ad type', adConfig);
        }
        return AdComponentTypeEnum.EMPTY;
    }
}

// If banner tag contains class "dfp" - it's dfp tag. In other case - it's custom banner
const determineBannerType = (adConfig) => {
    const dummyContainer = document.createElement('div');
    dummyContainer.innerHTML = adConfig.tag.value;

    if (dummyContainer.firstChild.classList.contains('dfp')) {
        return AdComponentTypeEnum.DFP;
    }
    return AdComponentTypeEnum.CUSTOM_BANNER;
};
