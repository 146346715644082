import logger from '../common/logger.service';
import abTestService from './ab-test.service';
import adunitPositionMemoizationService from './adunit-position-memoization.service';
import adTypeStrategy from '../ad-creation/ad-type-strategy.service';
import PLACEMENT_TO_CONFIG_NAME_MAP from '../../constants/placement-to-config-map.costant';
import PlacementConstant from '../../constants/placement.constant';
import Adstack from './adstack';
import CondAdstackIterator from './cond-adstack-iterator';
import ContentIntervalDistribution from './content-interval-distribution';
import BreakEvenlyDistribution from './break-evenly-distribution';
import { DistributionTypes } from '../../constants/configuration.constant';
import deviceInfo from '../common/device-detection.service';
import ContentTagsService from './content-tags.service';
import AnalyticsEventsService from '../analytics/analytics-events.service';
import ItemService from '../common/item.service';
import { AdComponentTypeEnum } from '../../constants/ad-types.constant';

let abTest;

export default class ConfigurationService {
    static setConfig(config) {
        abTest = abTestService.determineAbTest(config);

        if (window.PlaybuzzAd && window.PlaybuzzAd.metadata) {
            window.PlaybuzzAd.metadata.setAbTestMetadata({
                abTestName: abTestService.getAbTestName()
            });
        }

        AnalyticsEventsService.sendEvent(AnalyticsEventsService.EventTypeConstant.ADS_MODULE_LOADED);
    }

    static getConfigForPlacement(placement) {
        logger.debug('getting config for placement:', placement);

        if (!abTest) {
            logger.error('trying to create placement, when config isn\'t set', placement);
        }

        const adConfig = this._getAdConfig(placement);
        const adType = this._getAdType(adConfig, placement);

        return { adType, adConfig };
    }

    static _getAdConfig(placement) {
        let adConfig = {};
        if (abTestService.isContentTagsEnabled() && (
            placement.section === PLACEMENT_TO_CONFIG_NAME_MAP.SECTIONS.story
            || placement.section === PLACEMENT_TO_CONFIG_NAME_MAP.SECTIONS.unified
        )) {
            if (placement.tags && placement.tags.length) {
                ContentTagsService.getPositionsFromTags(placement.tags);
            }
            adConfig = ContentTagsService.getAdunitForPosition(abTest, placement);
            if (!adConfig) {
                adConfig = distributeFrequencyFromPreviousAdunits(placement);
            }
        } else {
            const memorizedAdUnit = adunitPositionMemoizationService.getAdUnit(placement);
            // Checking here explicitly for undefined, since "null" would mean that it is already calculated that ad has no config
            adConfig = (memorizedAdUnit === undefined) ? determineAdConfig(placement) : memorizedAdUnit;
        }
        return adConfig;
    }


    static _getAdType(config, placementData) {
        if (config) {
            // particle mode config is in "placement" object for regular adunits and in "tag" object for content tag adunit
            const particleModeConfig = config.placement.beforeParticleMode ? config.placement : config.tag;
            const { beforeParticleMode, beforeParticle } = (particleModeConfig || {});

            const { index, position, particleAbove } = placementData;
            if (
                position === PlacementConstant.POSITIONS.BETWEEN_PARTICLES
                && beforeParticle
                && beforeParticleMode
                && index > 0 // there is no "particle above" for index=0
            ) {
                const particleAboveType = particleAbove || ItemService.getItemTypeByIndex(index - 1);
                if (
                    (beforeParticleMode === 'include' && particleAboveType !== beforeParticle)
                    || (beforeParticleMode === 'exclude' && particleAboveType === beforeParticle)
                ) {
                    return AdComponentTypeEnum.EMPTY;
                }
            }
        }
        return adTypeStrategy.determineAdType(config);
    }

    static _getAdUnitForContentTags({ index }) {
        if (this.wasContentTagAdPlaced) {
            return null;
        }

        const adunitConfig = this.contentTagsService.getAdunitForPosition(this.adsConfig, index);
        if (adunitConfig) {
            const ad = this.PbAdsAdapterService.getConfig(adunitConfig);
            ad.positionType = 'content tag';
            this.wasContentTagAdPlaced = true;
            return ad;
        }
        return null;
    }
}

const determineAdConfig = (placement) => {
    if (!placement || !abTest) {
        return null;
    }

    const sectionName = PLACEMENT_TO_CONFIG_NAME_MAP.SECTIONS[placement.section];
    const sectionConfig = abTest[sectionName];

    if (!sectionConfig) {
        return null;
    }

    const dataForDistributionCalculation = {
        sectionName,
        sectionConfig,
        placement,
    };

    distributeFixedAdunits(dataForDistributionCalculation);
    distributeSmartAdunits(dataForDistributionCalculation);
    distributeAdstackAdunits(dataForDistributionCalculation);

    return adunitPositionMemoizationService.getAdUnit(placement);
};

const distributeFixedAdunits = ({ sectionName, sectionConfig, placement }) => {
    if (!sectionName || !sectionConfig || !sectionConfig.placement || !sectionConfig.placement.fixed) {
        return;
    }

    const positionName = PLACEMENT_TO_CONFIG_NAME_MAP.POSITIONS[placement.position];

    const fixedAdunits = sectionConfig.placement.fixed
        .filter((fixedPlacement) => {
            const placementConfig = fixedPlacement.placement;
            return !placementConfig.smart && placementConfig.mode === positionName;
        })
        .filter(filterOutByTargeting);

    for (let i = 0; i < placement.length + 1; i++) {
        const placementToSet = Object.assign({}, placement, { index: i });
        adunitPositionMemoizationService.setAdUnit(placementToSet, null);
    }

    fixedAdunits.forEach((adunit) => {
        let location = adunit.placement.location || 0;

        if (location === -1) {
            location = placement.length;
        }

        const placementToSet = Object.assign({}, placement, { index: location });
        adunitPositionMemoizationService.setAdUnit(
            placementToSet,
            Object.assign({}, adunit, { positionType: 'fixed' })
        );
    });
};

const distributeSmartAdunits = ({ sectionName, sectionConfig, placement }) => { // eslint-disable-line no-unused-vars
    // TODO MP: to be implemented
};

const distributeAdstackAdunits = ({ sectionName, sectionConfig, placement }) => { // eslint-disable-line no-unused-vars
    if (!sectionConfig.placement || !sectionConfig.placement.regular || !sectionConfig.placement.regular.length || !placement.length) {
        return;
    }
    const numOfPlacements = placement.length + 1; // if there are 5 particles, that would mean 6 placements
    const distributionIterator = getDistributionIterator(sectionConfig.distribution, numOfPlacements);
    const positionName = PLACEMENT_TO_CONFIG_NAME_MAP.POSITIONS[placement.position];

    const adstackPlacements = sectionConfig.placement.regular
        .filter((fixedPlacement) => {
            const placementConfig = fixedPlacement.placement;
            return placementConfig.mode === positionName;
        })
        .filter(filterOutByTargeting);

    const adIterator = new CondAdstackIterator(new Adstack(adstackPlacements), adstackPlacements.length);
    let position = distributionIterator.getNextPosition();
    let { maxAds } = sectionConfig.distribution;
    // if maxAds is null or 0 it means there is no limitation for maxAds.
    // TODO: refactor
    maxAds = maxAds || 1000;
    let numOfAdsPlaced = 0;

    while (
        adIterator.hasNextAd()
        && position !== null
        && numOfAdsPlaced < maxAds
    ) {
        const placementToSet = Object.assign({}, placement, { index: position });
        const adToPlace = adIterator.getNextAd(ad => (
            ConfigurationService._getAdType(ad, placementToSet) !== AdComponentTypeEnum.EMPTY
        ));
        if (adToPlace) {
            adunitPositionMemoizationService.setIfNotExists(
                placementToSet,
                Object.assign({}, adToPlace, { positionType: 'ad stack' })
            );
            numOfAdsPlaced += 1;
        }
        position = distributionIterator.getNextPosition();
    }
};

const getDistributionIterator = ({ frequency, model, startFrom, priority }, numOfPositions) => {
    if (model === DistributionTypes.PARTICLE_INTERVAL) {
        return new ContentIntervalDistribution({ frequency, startFrom, numOfPositions, priority });
    }
    if (model === DistributionTypes.BREAK_EVENLY_INTERVAL) {
        return new BreakEvenlyDistribution({ frequency, startFrom, numOfPositions });
    }

    const errorMessage = 'unknown distribution model for adstack';

    logger.error(errorMessage);


    throw new Error(errorMessage);
};

const distributeFrequencyFromPreviousAdunits = ({ section, index }) => {
    const contentTagPosition = ContentTagsService.getCalculatedPosition();

    if (contentTagPosition === null) {
        return null;
    }

    // update the placemnts location with accumulating the locationAfterPreviousParticle value from previous placements
    const placementLocationMapper = (adUnit, i, arr) => {
        if (adUnit.placement.locationAfterPreviousParticle === undefined) {
            return null;
        }

        // If the first ad unit in the array, the location should be need relative to the contet tag position,
        // For all the others, the location is relative to last ad unit in the array
        if (i === 0) {
            adUnit.placement.location = adUnit.placement.locationAfterPreviousParticle + contentTagPosition;
        } else {
            const previousPlacement = arr[i - 1];
            adUnit.placement.location = adUnit.placement.locationAfterPreviousParticle + previousPlacement.placement.location;
        }

        return adUnit;
    };

    const placementLocationFilter = adunit => adunit != null && adunit.placement.location === index;

    const sectionConfig = abTest[section];
    const placements = sectionConfig.placement.regular.map(placementLocationMapper).filter(placementLocationFilter);

    if (placements.length > 0) {
        const adUnit = placements[0];
        const { locationAfterPreviousParticle } = adUnit.placement.locationAfterPreviousParticle;
        adUnit.positionType = `Frequency From Previous Placement:
                ${locationAfterPreviousParticle} ${locationAfterPreviousParticle === 1 ? 'particle' : 'particles'} after previous placement`;
        return adUnit;
    }
    return null;
};


const filterOutByTargeting = (adUnit) => {
    if (!adUnit) {
        return false;
    }

    const { device, os } = adUnit.targeting;
    return (device === 'all' || device === deviceInfo.device) && (os === 'all' || os === deviceInfo.os);
};
