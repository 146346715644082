import logger from './logger.service';

const INIT_STATUS = {
    PENDING: 0,
    IN_PROGRESS: 1,
    DONE: 2,
    FAILED: 3
};

/**
 * @callback LazyDep~Provider
 * @param {Function} get - dependency getter
 * @return {Promise.<*>}
 */

class LazyDep {
    constructor() {
        this._providers = {};
        this._initStatus = {};
        this.provide = this.provide.bind(this);
        this.get = this.get.bind(this);
    }

    /**
     * Registrate new provider
     * @param {*} name - provider name
     * @param {LazyDep~Provider} initFn - initialization function
     */
    provide(name, initFn) {
        if (this._providers[name]) {
            logger.error(`LazyDep: try to override provider "${name}"`);
            return;
        }
        if (typeof initFn !== 'function') {
            logger.error(`LazyDep: provider "${name}" init function should be passed`);
            return;
        }
        this._providers[name] = {
            init: initFn,
            value: undefined
        };
        this._initStatus[name] = INIT_STATUS.PENDING;
    }

    /**
     * Return associated value, inits provider if it is not initialized
     * @param {string} name
     * @return {Promise.<*>}
     */
    get(name) {
        return new Promise((resolve, reject) => {
            const provider = this._providers[name];
            if (!provider) {
                reject(new Error(`LazyDep: provider "${name}" is not exist`));
            } else if (this._initStatus[name] === INIT_STATUS.DONE) {
                resolve(provider.value);
            } else if (this._initStatus[name] === INIT_STATUS.FAILED) {
                reject(new Error(`LazyDep: provider "${name}" failed`));
            } else if (this._initStatus[name] === INIT_STATUS.IN_PROGRESS) {
                reject(new Error(`LazyDep: circular dependecy detected ("${name}")`));
            } else {
                try {
                    this._initStatus[name] = INIT_STATUS.IN_PROGRESS;
                    Promise.resolve(provider.init(this.get)).then(
                        (value) => {
                            this._initStatus[name] = INIT_STATUS.DONE;
                            this._providers[name].value = value;
                            resolve(value);
                        },
                        (error) => {
                            this._initStatus[name] = INIT_STATUS.FAILED;
                            logger.error(error);
                            reject(error);
                        }
                    );
                } catch (error) {
                    this._initStatus[name] = INIT_STATUS.FAILED;
                    logger.error(error);
                    reject(error);
                }
            }
        });
    }
}

export default new LazyDep();
