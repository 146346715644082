/* eslint-disable */

const logger = {
  init: () => {
    if (!document.querySelector('script[src*="datadoghq"]')) {
      (function(h, o, u, n, d) {
        h = h[d] = h[d] || { q: [], onReady: function(c) { h.q.push(c); } };
        d = o.createElement(u); d.async = 1; d.src = n;
        n = o.getElementsByTagName(u)[0]; n.parentNode.insertBefore(d, n);
      })(window, document, 'script', 'https://www.datadoghq-browser-agent.com/us1/v6/datadog-logs.js', 'DD_LOGS');
    }
    window.DD_LOGS.onReady(() => {
      if (window.DD_LOGS.init) {
        window.DD_LOGS.init({
          clientToken: 'pub7d65532354d297fc2bc2fa200c8f4942',
          site: 'datadoghq.com',
          service: 'player-ng',
          forwardErrorsToLogs: false,
          forwardConsoleLogs: [],
          sessionSampleRate: 100,
          env: 'prod',
        });
      }
    });
  },
  log: (message, data) => {
    window.DD_LOGS.logger.info(message, data);
  },
  error: (message, data) => {
    window.DD_LOGS.logger.error(message, data);
  }
};

export default logger;
