export default class BreakEvenlyDistribution {
    constructor({ numOfPositions, frequency = 1, startFrom = 0 } = {}) {
        this._positionsNum = numOfPositions;
        this._currentPosition = null;
        this._step = this._positionsNum * frequency / 100;
        this._startFrom = startFrom;
    }

    /**
     * Gets next available placement according to configuration
     * @returns {Number} Index of next placement, null if no placements left
     */
    getNextPosition() {
        if (this._currentPosition >= this._positionsNum) {
            return null;
        }

        if (this._currentPosition === null) {
            this._currentPosition = this._startFrom;
        } else {
            this._currentPosition = Math.ceil(this._currentPosition + this._step);
        }

        return (this._currentPosition >= this._positionsNum) ? null : this._currentPosition;
    }
}
