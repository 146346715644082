export default {
    SECTIONS: {
        STORY: 'story',
        QUIZ: 'quizSection',
        POLL: 'pollSection',
        TRIVIA: 'triviaSection',
        CLOSED_TRIVIA: 'closedTriviaSection',
        VIDEO_CREATOR: 'videoCreatorSection',
        UNIFIED: 'unified'
    },
    POSITIONS: {
        BETWEEN_PARTICLES: 'between-particles',
        BETWEEN_QUESTIONS: 'between-questions',
        IN_QUESTION: 'in-question',
        VIDEO_CREATOR: 'video-creator',
    },
};
