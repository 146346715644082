import { getQueryStringValue } from '@playbuzz/client-utils';
import logger from '../common/logger.service';
import adunitPositionMemoizationService from './adunit-position-memoization.service';

let abTest;

export default class abTestService {
    /**
     * @param {Object} config - Configuration with abtests
     * @param {Array} config.abTests - Array of A/B tests
     * @returns {Object} configuration inside abtest, null id no valid A/B test is found
     */
    static determineAbTest(config) {
        if (abTest !== undefined) {
            return abTest;
        }

        if (!config || !config.abTests || !config.abTests.length) {
            abTest = null;
            return null;
        }

        abTest = getAbTestFromUrl(config);
        logger.debug('abTest from url is', abTest);

        if (abTest === undefined) {
            abTest = getAbTestFromUserData(config);
            logger.debug('abTest from user data is', abTest);
        }

        if (abTest === undefined) {
            abTest = getRandomAbTestByWeights(config);
            logger.debug('abTest from weights distribution is', abTest);
        }

        if (abTest) {
            adunitPositionMemoizationService.setMaxAds(abTest.maxAds);
        }

        logger.log('determined abtest test to be', abTest);
        return abTest && abTest.configuration;
    }

    /**
     * @returns {String} Name of active A/B test, null if no A/B test was picked
     */
    static getAbTestName() {
        if (abTest === undefined) {
            const errorMessage = 'Trying to get abtest before config was set';

            logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        return abTest && abTest.name;
    }

    static isContentTagsEnabled() {
        if (abTest === undefined) {
            const errorMessage = 'Trying to get abtest before config was set';

            logger.error(errorMessage);
            throw new Error(errorMessage);
        }

        return abTest && abTest.contentTagsEnabled;
    }

    static _clearAbTestInMemoryValue() {
        abTest = undefined;
    }
}


const getAbTestFromUrl = (config) => {
    const abtestFromUrl = getQueryStringValue('pbads_abtest');

    return getAbtestByName(config, abtestFromUrl);
};

const getAbTestFromUserData = (config) => {
    try {
        const storedAbtestData = localStorage.getItem(`playbuzzads_abtest_${window.location.host}`);

        if (!storedAbtestData) {
            return undefined;
        }

        const abtestData = JSON.parse(storedAbtestData);

        // If config was changed, current A/B test value can't be used and should be reevaluated
        if (isAbTestConfigChanged(config, abtestData)) {
            return undefined;
        }

        return getAbtestByName(config, abtestData.selectedAbtest);
    } catch (e) {
        logger.error('Couldn\'t parse stored abtest configuration');
    }

    return undefined;
};

// Random selection with relative weights
const getRandomAbTestByWeights = (config) => {
    const randomParam = Math.random();
    const weightSum = config.abTests.reduce((sum, abtest) => sum + Number(abtest.weight), 0);
    let currentRangeEnd = 0;
    let selectedAbtest;

    for (let i = 0; i < config.abTests.length; i++) {
        const abtest = config.abTests[i];
        currentRangeEnd += Number(abtest.weight) / weightSum;

        if (randomParam < currentRangeEnd) {
            selectedAbtest = abtest;
            break;
        }
    }

    setAbTestToUserData(config, selectedAbtest.name);

    return selectedAbtest;
};

const setAbTestToUserData = ({ abTests }, selectedAbtest) => {
    const abtestsMap = abTests.reduce((map, abtest) => {
        map[abtest.name] = abtest.weight;

        return map;
    }, {});

    localStorage.setItem(`playbuzzads_abtest_${window.location.host}`, JSON.stringify({
        selectedAbtest,
        abtestsMap
    }));
};

// If any A/B test name or weight was changed, it is considered a change of configauration
const isAbTestConfigChanged = ({ abTests }, { abtestsMap }) => abTests.some(abtest => abtestsMap[abtest.name] !== abtest.weight);

const getAbtestByName = ({ abTests }, abtestName) => {
    if (!abtestName) {
        return undefined;
    }

    return abTests.filter(abtest => abtest.name === abtestName)[0];
};
